import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class EditSubregionStateHandler {
  initEditSubregionScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.EditSubregion;
    callback(this.state);
  }

  updateEditSubregionScreen(
    this: AppStateHandler,
    newFormValues: {
      subregionName?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.subregionName !== undefined) {
      this.state.editSubregionScreen.subregionName =
        newFormValues.subregionName;
    }
    callback(this.state);
  }

  saveEditSubregionScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.editSubregionScreen.subregionName === "") {
      this.state.editSubregionScreen.formError = "Vul een deelgebied naam in";
      callback(this.state);
      setTimeout(() => {
        this.state.editSubregionScreen.formError = "";
        callback(this.state);
      }, 2000);
    } else {
      this.state.loading = true;
      this.state.editSubregionScreen.successMessage = "Deelgebied opgeslagen";
      callback(this.state);
      setTimeout(() => {
        this.state.editSubregionScreen.successMessage = "";
        callback(this.state);
      }, 2000);
    }
  }
}
