import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import Section from "@shared/components/Section";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import "./EditSubregionScreen.css";

interface EditSubregionScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class EditSubregionScreen extends React.Component<EditSubregionScreenProps> {
  render() {
    return (
      <div id="EditSubregionScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klanten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klant bewerken",
              callback: () => {
                if (this.props.state.clientEditScreen.id) {
                  this.props.stateHandler.edit_client_init_screen(
                    this.props.state.clientEditScreen.id,
                    this.props.updateStateCallback
                  );
                }
              },
            },
            {
              title: "Deelgebied bewerken",
            },
          ]}
        ></BreadCrumbs>

        <Section disabled={false}>
          {(() => {
            if (this.props.state.editSubregionScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.editSubregionScreen.formError}
                ></Alert>
              );
            }
            if (this.props.state.editSubregionScreen.successMessage !== "") {
              return (
                <Alert
                  Error={false}
                  Message={this.props.state.editSubregionScreen.successMessage}
                ></Alert>
              );
            }
          })()}
          <table className="EditSubregionScreen-Table">
            <tbody>
              <tr>
                <td>Deelgebied naam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Deelgebied naam"
                    onChange={(newValue) => {
                      this.props.stateHandler.updateEditSubregionScreen(
                        { subregionName: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.editSubregionScreen.subregionName}
                  ></TextInput>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.saveEditSubregionScreen(
                  this.props.updateStateCallback
                );
              },
              disabled: false,
            },
            {
              title: "Annuleren",
              callback: () => {
                if (this.props.state.clientEditScreen.id) {
                  this.props.stateHandler.edit_client_init_screen(
                    this.props.state.clientEditScreen.id,
                    this.props.updateStateCallback
                  );
                }
              },
              disabled: false,
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
