import { DocumentationTypeEnum } from "@shared/client/lib/models/DocumentationTypeEnum";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import CheckBox from "@shared/components/CheckBox";
import DropDown from "@shared/components/DropDown";
import FileInput from "@shared/components/FileInput";
import Section from "@shared/components/Section";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import {
  DocumentationToDropDown,
  DocumentationTypeToIndex,
  IndexToDocumentationType,
} from "../../helperFunctions";
import { AppStateType, ScreenState } from "../../Types";
import "./DocumentationScreen.css";

interface NewDocumentationScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class NewDocumentationScreen extends React.Component<NewDocumentationScreenProps> {
  render() {
    return (
      <div id="DocumentationScreen">
        <BreadCrumbs
          data={[
            {
              title: "Documentatie",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.DocumentationList,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Nieuwe documentatie",
            },
          ]}
        ></BreadCrumbs>
        {(() => {
          if (this.props.state.newDocumentationScreen.message !== "") {
            return (
              <Alert
                Error={true}
                Message={this.props.state.newDocumentationScreen.message}
              ></Alert>
            );
          }
        })()}
        <Section>
          <table className="DocumentationScreen-Table">
            <tbody>
              <tr>
                <td>Naam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Naam"
                    onChange={(newName) => {
                      this.props.stateHandler.newDocumentationForm(
                        { name: newName },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newDocumentationScreen.name}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Beschrijving*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Beschrijving"
                    onChange={(newDescription) => {
                      this.props.stateHandler.newDocumentationForm(
                        { description: newDescription },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newDocumentationScreen.description}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Type</td>
                <td>
                  <DropDown
                    list={DocumentationToDropDown()}
                    onChange={(newKey: number) => {
                      this.props.stateHandler.newDocumentationForm(
                        { documentationType: IndexToDocumentationType(newKey) },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={DocumentationTypeToIndex(
                      this.props.state.newDocumentationScreen.documentationType
                    )}
                  ></DropDown>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section>
          <table className="DocumentationScreen-Table">
            <tbody>
              <tr>
                {(() => {
                  if (
                    this.props.state.newDocumentationScreen
                      .documentationType ===
                    DocumentationTypeEnum.InstructionalVideo
                  ) {
                    return (
                      <>
                        <td>YouTube link*</td>
                        <td>
                          <TextInput
                            type={TextInputType.Text}
                            placeholder="YouTube link"
                            value={
                              this.props.state.newDocumentationScreen.videoUrl
                            }
                            onChange={(newVideourl) => {
                              this.props.stateHandler.newDocumentationForm(
                                { videoUrl: newVideourl },
                                this.props.updateStateCallback
                              );
                            }}
                          ></TextInput>
                        </td>
                      </>
                    );
                  } else {
                    return (
                      <>
                        {" "}
                        <td>PDF document*</td>
                        <td>
                          <FileInput
                            onChange={(event) => {
                              this.props.stateHandler.newDocumentationForm(
                                { files: event.target.files },
                                this.props.updateStateCallback
                              );
                            }}
                          ></FileInput>
                        </td>
                      </>
                    );
                  }
                })()}
              </tr>
            </tbody>
          </table>
        </Section>

        <Section>
          <SectionTitle subtitle={true}>Zichtbaar voor klant</SectionTitle>
          <table className="DocumentationScreen-Table">
            <tbody>
              {this.props.state.documentationAccess.map((item) => {
                return (
                  <tr key={item.client_id}>
                    <td>{item.client_name}</td>
                    <td>
                      <CheckBox
                        checked={item.access}
                        onClick={() => {
                          this.props.stateHandler.newDocumentationForm(
                            { access: item.client_id },
                            this.props.updateStateCallback
                          );
                        }}
                      ></CheckBox>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>
        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.createDocumentation(
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.DocumentationList,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
