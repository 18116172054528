import {
  ClientTemplateModel,
  ClientTemplateModelHistory,
  ClientTemplateReport,
  DocumentationSuperAdmin,
  DocumentationTypeEnum,
  Log,
  RoleEnum,
  StateEnum,
} from "@shared/client/lib";

export enum ScreenState {
  Login = "Login",
  LoginMFA = "LoginMFA",
  Clients = "Clients",
  EditClient = "EditClient",
  AddModelTemplate = "AddModelTemplate",
  EditModelTemplate = "EditModelTemplate",
  AddReportTemplate = "AddReportTemplate",
  EditReportTemplate = "EditReportTemplate",
  User = "User",
  DocumentationList = "DocumentationList",
  EditDocumentation = "EditDocumentation",
  NewDocumentation = "NewDocumentation",

  Logs = "Logs",
  ClientLog = "ClientLog",
  SetupMfa = "SetupMfa",
  CreateClient = "CreateClient",
  EditSubregion = "EditSubregion",
  SamlClient = "SamlClient",
}

export interface AppStateType {
  loggedIn: boolean;
  currentUser: {
    fullName: string;
    userRole: RoleEnum;
    accessToken: string | null;
    userEmail: string;
    mfaVerified: boolean;
    mfaForced: boolean;
    mfaInput: string;
  };
  LoginScreen: {
    login_error: string;
  };
  // loginState: LoginState;
  screenState: ScreenState;
  mfaRequired: boolean;
  login2fa: {
    useremail: string;
    infoMessage: string;
    errorMessage: string;
    mfaInput: string;
  };
  // Setup MFA
  setupMfa: {
    otpauth_url: string;
    errorMessage: string;
    mfaCode: string;
  };
  // // Reset password
  // ResetPassword: {
  //   email: string;
  //   resetPasswordCode: string;
  //   mfaenabled: boolean;
  //   mfatoken: string;
  //   resetpasswordMessage: string;
  //   oldPassword: string;
  //   newPassword: string;
  //   newPasswordRepeat: string;
  //   newPasswordError: string;
  //   newPasswordinfo: string;
  // };

  loading: boolean;

  clientsScreen: {
    clients: {
      id: number;
      name: string;
      subdomain: string;
    }[];
  };

  clientEditScreen: {
    loadingClientName: boolean;
    id: number | null;
    name: string;
    subdomain: string;

    loadingMapViewerSettings: boolean;
    mapViewerHost: string;
    mapViewerClientId: string;
    mapViewerClientSecret: string;
    mapViewerRedirectUri: string;
    mapViewerApiKeyId: string;
    mapViewerApiKey: string;

    loadingTemplateModelList: boolean;
    templateModelList: ClientTemplateModel[];

    loadingTemplateReportList: boolean;
    templateReportList: ClientTemplateReport[];
  };
  samlscreen: {
    client_id: number;
    loadingSamlData: boolean;
    samlEnabled: boolean;
    samlEnabledSavedValue: boolean;
    samlEntityId: string;
    samlSSOService: string;
    samlSLService: string;
    samlCertificate: string;
    saml_grex_sp_EntityId: string;
    saml_grex_sp_ACS: string;
    saml_grex_sp_sls: string;

    new_samlEntityId: string;
    new_samlSSOService: string;
    new_samlSLService: string;
    new_samlCertificate: string;
    new_saml_form_error: string;
    new_saml_form_info: string;
  };

  logScreen: {
    logs: Log[];
  };

  clientLogScreen: {
    logs: Log[];
  };

  userScreen: {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    mfaVerified: boolean;
    mfaForced: boolean;
    infoMessage: string;
    errorMessage: string;
  };

  userNewPasswordScreen: {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
    newPasswordError: string;
    newPasswordinfo: string;
  };

  newClientScreen: {
    clientName: string;
    availableClientName: boolean;
    message: string;
  };

  PasswordPolicy: {
    newPasswordRequirements: string[];
    notAppliedRequirements: string[];
  };

  addModelTemplateScreen: {
    formError: string;
    displayName: string;
    description: string;
    datasheetName: string;
    file: File | null;
  };

  editModelTemplateScreen: {
    id: number | null;
    formError: string;
    successMessage: string;
    displayName: string;
    description: string;
    datasheetName: string;
    file: File | null;
    history: Array<ClientTemplateModelHistory>;
  };

  addReportTemplateScreen: {
    formError: string;
    displayName: string;
    description: string;
    status: StateEnum;
    nModelsMin: string;
    nModelsMax: string;
    file: File | null;
  };

  editReportTemplateScreen: {
    id: number | null;
    formError: string;
    successMessage: string;
    displayName: string;
    description: string;
    status: StateEnum;
    nModelsMin: string;
    nModelsMax: string;
    file: File | null;
    history: Array<ClientTemplateModelHistory>;
  };

  documentationList: DocumentationSuperAdmin[];
  selectedDocumentation: DocumentationSuperAdmin | null;
  editDocumentationScreen: {
    name: string;
    description: string;
    pdfPath: string;
    file: File | null;
    videoUrl: string;
    documentationType: string;
    access: Array<number>;
    message: string;
  };
  documentationAccess: [
    {
      client_id: number;
      client_name: string;
      access: boolean;
    }
  ];
  newDocumentationScreen: {
    name: string;
    description: string;
    documentationType: DocumentationTypeEnum;
    pdfPath: string;
    file: File | null;
    videoUrl: string;
    access: Array<number>;
    message: string;
  };

  editSubregionScreen: {
    formError: string;
    successMessage: string;
    subregionName: string;
  };
}
