import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class ClientsStateHandler {
  clients_init_screen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Clients;
    callback(this.state);

    this.grexSuperAdminClientApi
      .apiGrexsuperadminClientList()
      .then((response) => {
        this.state.clientsScreen.clients = response.map((x) => {
          return {
            id: x.id,
            name: x.name,
            subdomain: x.schemaName,
          };
        });
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  edit_client_button_clicked(
    this: AppStateHandler,
    clientId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.edit_client_init_screen(clientId, callback);
    this.changeScreen(ScreenState.EditClient, callback);
  }

  add_client_button_clicked(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.changeScreen(ScreenState.CreateClient, callback);
  }
}
