import "./App.css";
import React from "react";
import { AppStateHandler } from "./AppStateHandler";
import LoginScreen from "./screens/auth/LoginScreen";
import MenuBar from "./components/MenuBar";
import LoadingOverlay from "@shared/components/LoadingOverlay";
import { AppStateType, ScreenState } from "./Types";
import MyAccountScreen from "./screens/user/UserScreen";
import ClientsScreen from "./screens/clients/ClientsScreen";
import LoginMfaScreen from "./screens/auth/LoginMfaScreen";
import EditClientScreen from "./screens/clients/EditClientScreen";
import LogScreen from "./screens/log/LogScreen";
import SetupMfaScreen from "./screens/user/SetupMfaScreen";
import CreateClientScreen from "./screens/clients/CreateClientScreen";
import AddModelTemplateScreen from "./screens/clients/AddModelTemplateScreen";
import AddReportTemplateScreen from "./screens/clients/AddReportTemplateScreen";
import ClientLogScreen from "./screens/clients/ClientLogScreen";
import DocumentationListScreen from "./screens/docs/DocumentationListScreen";
import DocumentationScreen from "./screens/docs/DocumentationScreen";
import NewDocumentationScreen from "./screens/docs/NewDocumentationScreen";
import EditSubregionScreen from "./screens/clients/EditSubregionScreen";
import EditModelTemplateScreen from "./screens/clients/EditModelTemplateScreen";
import EditReportTemplateScreen from "./screens/clients/EditReportTemplateScreen";
import SamlClientScreen from "./screens/clients/SamlClientScreen";
export default class App extends React.Component {
  stateHandler = new AppStateHandler();
  state: AppStateType;

  constructor(props: any) {
    super(props);
    this.state = this.stateHandler.getState();
  }

  componentDidMount() {
    this.stateHandler.initApp((newState) => {
      this.setState(newState);
    });
  }

  render() {
    return (
      <>
        {(() => {
          if (!this.state.loggedIn) {
            return (
              <LoginScreen
                state={this.state}
                stateHandler={this.stateHandler}
                updateStateCallback={(newState) => {
                  this.setState(newState);
                }}
              ></LoginScreen>
            );
          } else if (this.state.mfaRequired) {
            return (
              <LoginMfaScreen
                state={this.state}
                stateHandler={this.stateHandler}
                updateStateCallback={(newState) => {
                  this.setState(newState);
                }}
              ></LoginMfaScreen>
            );
          } else {
            return (
              <div id="App-Container">
                {(() => {
                  if (this.state.loading) {
                    return <LoadingOverlay />;
                  }
                })()}

                <MenuBar
                  state={this.state}
                  stateHandler={this.stateHandler}
                  updateStateCallback={(newState) => {
                    this.setState(newState);
                  }}
                ></MenuBar>
                <div className="App-Screen">
                  {(() => {
                    if (this.state.screenState === ScreenState.Clients) {
                      return (
                        <ClientsScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        ></ClientsScreen>
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditClient
                    ) {
                      return (
                        <EditClientScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        ></EditClientScreen>
                      );
                    } else if (this.state.screenState === ScreenState.Logs) {
                      return (
                        <LogScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.ClientLog
                    ) {
                      return (
                        <ClientLogScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (this.state.screenState === ScreenState.User) {
                      return (
                        <MyAccountScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.SetupMfa
                    ) {
                      return (
                        <SetupMfaScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.CreateClient
                    ) {
                      return (
                        <CreateClientScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.AddModelTemplate
                    ) {
                      return (
                        <AddModelTemplateScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.AddReportTemplate
                    ) {
                      return (
                        <AddReportTemplateScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditReportTemplate
                    ) {
                      return (
                        <EditReportTemplateScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditModelTemplate
                    ) {
                      return (
                        <EditModelTemplateScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.DocumentationList
                    ) {
                      return (
                        <DocumentationListScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditDocumentation
                    ) {
                      return (
                        <DocumentationScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.NewDocumentation
                    ) {
                      return (
                        <NewDocumentationScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.EditSubregion
                    ) {
                      return (
                        <EditSubregionScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    } else if (
                      this.state.screenState === ScreenState.SamlClient
                    ) {
                      return (
                        <SamlClientScreen
                          state={this.state}
                          stateHandler={this.stateHandler}
                          updateStateCallback={(newState) => {
                            this.setState(newState);
                          }}
                        />
                      );
                    }
                  })()}
                </div>
              </div>
            );
          }
        })()}
      </>
    );
  }
}
