import { ApiGrexsuperadminClientSamlCreateRequest } from "@shared/client/lib/apis/GrexSuperAdminSAMLApi";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class SamlClientStateHandler {
  loadSamlForm(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.SamlClient;

    // this.state.samlscreen.loadingSamlData = true;
    this.state.samlscreen.samlEnabled = false;
    this.state.samlscreen.samlEntityId = "";
    this.state.samlscreen.samlSSOService = "";
    this.state.samlscreen.samlSLService = "";
    this.state.samlscreen.samlCertificate = "";
    this.state.samlscreen.saml_grex_sp_EntityId = "";
    this.state.samlscreen.saml_grex_sp_ACS = "";
    this.state.samlscreen.saml_grex_sp_sls = "";

    this.grexSuperAdminSAMLApi
      .apiGrexsuperadminClientSamlRetrieve({
        id: this.state.samlscreen.client_id,
      })
      .then((response) => {
        this.state.samlscreen.samlEnabled = response.samlActive || false;
        this.state.samlscreen.samlEntityId = response.idpEntityId;
        this.state.samlscreen.samlSSOService = response.idpSsoUrl;
        this.state.samlscreen.samlSLService = response.idpSloUrl;
        this.state.samlscreen.saml_grex_sp_EntityId = response.spEntityId;
        this.state.samlscreen.saml_grex_sp_ACS = response.spAcsUrl;
        this.state.samlscreen.saml_grex_sp_sls = response.spSlsUrl;
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  samlForm(
    this: AppStateHandler,
    new_saml: {
      new_samlEntityId?: string;
      new_samlSSOService?: string;
      new_samlSLService?: string;
      new_samlCertificate?: string;
    },

    callback: (newState: AppStateType) => void
  ) {
    if (new_saml.new_samlEntityId !== undefined) {
      this.state.samlscreen.new_samlEntityId = new_saml.new_samlEntityId;
    }
    if (new_saml.new_samlSSOService !== undefined) {
      this.state.samlscreen.new_samlSSOService = new_saml.new_samlSSOService;
    }
    if (new_saml.new_samlSLService !== undefined) {
      this.state.samlscreen.new_samlSLService = new_saml.new_samlSLService;
    }
    if (new_saml.new_samlCertificate !== undefined) {
      this.state.samlscreen.new_samlCertificate = new_saml.new_samlCertificate;
    }
    this.state.samlscreen.new_saml_form_error = "";
    callback(this.state);
  }

  vlaidateSamlForm(this: AppStateHandler) {
    const list_of_strings: string[] = [
      this.state.samlscreen.new_samlEntityId,
      this.state.samlscreen.new_samlSSOService,
      this.state.samlscreen.new_samlSLService,
      this.state.samlscreen.new_samlCertificate,
    ];
    const isEmptyStringNotInList = !list_of_strings.includes("");
    if (isEmptyStringNotInList) {
      this.state.samlscreen.new_saml_form_error = "";
      return true;
    }
    this.state.samlscreen.new_saml_form_error = "Alle velden zijn verplicht";
    return false;
  }
  saveSaml(this: AppStateHandler, callback: (newState: AppStateType) => void) {
    if (!this.vlaidateSamlForm()) {
      callback(this.state);
      return;
    }
    const request: ApiGrexsuperadminClientSamlCreateRequest = {
      id: this.state.samlscreen.client_id,
      sAMLServiceProvider: {
        entityId: this.state.samlscreen.new_samlEntityId,
        singleSignOnService: this.state.samlscreen.new_samlSSOService,
        singleLogoutService: this.state.samlscreen.new_samlSLService,
        x509cert: this.state.samlscreen.new_samlCertificate,
      },
    };

    this.grexSuperAdminSAMLApi
      .apiGrexsuperadminClientSamlCreate(request)
      .then(
        (response) => {
          this.state.samlscreen.samlEnabled = true;
          this.state.samlscreen.samlEntityId = response.idpEntityId;
          this.state.samlscreen.samlSSOService = response.idpSsoUrl;
          this.state.samlscreen.samlSLService = response.idpSloUrl;
          this.state.samlscreen.saml_grex_sp_EntityId = response.spEntityId;
          this.state.samlscreen.saml_grex_sp_ACS = response.spAcsUrl;
          this.state.samlscreen.saml_grex_sp_sls = response.spSlsUrl;
          this.state.samlscreen.new_saml_form_info = "SAML gegevens opgeslagen";
          setTimeout(() => {
            this.state.samlscreen.new_saml_form_info = "";
            callback(this.state);
          }, 2000);
          callback(this.state);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }
}
