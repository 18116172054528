import "./MenuBar.css";
import React from "react";
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import { FiLogOut, FiUser, FiUsers, FiBook, FiArchive } from "react-icons/fi";
import { AppStateType, ScreenState } from "../Types";
import { AppStateHandler } from "../AppStateHandler";

interface MenuBarProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class MenuBar extends React.Component<MenuBarProps> {
  render() {
    return (
      <div id="MenuBar-Holder">
        <div id="MenuBar-Main">
          <GrexManagerLogo
            onClick={() => {
              this.props.stateHandler.changeScreen(
                ScreenState.Clients,
                this.props.updateStateCallback
              );
            }}
          />

          <div id="MenuBar-MenuScrollHolder">
            <div className="MenuBar-MenuFlexHolder">
              <div>
                <div
                  className={
                    this.props.state.screenState === ScreenState.Clients
                      ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                      : "MenuBar-NavItem MenuBar-NavItemMargin"
                  }
                  onClick={(_) => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.Clients,
                      this.props.updateStateCallback
                    );
                  }}
                >
                  <div className="MenuBar-NavIcon">
                    <FiUsers />
                  </div>
                  <div className="MenuBar-NavText">Leden</div>
                </div>

                <div
                  className={
                    this.props.state.screenState ===
                    ScreenState.DocumentationList
                      ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                      : "MenuBar-NavItem MenuBar-NavItemMargin"
                  }
                  onClick={(_) => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.DocumentationList,
                      this.props.updateStateCallback
                    );
                  }}
                >
                  <div className="MenuBar-NavIcon">
                    <FiBook />
                  </div>
                  <div className="MenuBar-NavText">Documentatie</div>
                </div>

                <div
                  className={
                    this.props.state.screenState === ScreenState.Logs
                      ? "MenuBar-NavItem MenuBar-NavItemSelected"
                      : "MenuBar-NavItem"
                  }
                  onClick={(_) => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.Logs,
                      this.props.updateStateCallback
                    );
                  }}
                >
                  <div className="MenuBar-NavIcon">
                    <FiArchive />
                  </div>
                  <div className="MenuBar-NavText">Logs</div>
                </div>
              </div>
            </div>
          </div>

          <div id="MenuBar-UserItemFlexHolder">
            <div id="MenuBar-UserItemHolder">
              <div
                className={"MenuBar-NavItem"}
                onClick={(_) => {
                  this.props.stateHandler.logout(
                    this.props.updateStateCallback
                  );
                }}
              >
                <div className="MenuBar-NavIcon">
                  <FiLogOut />
                </div>
                <div className="MenuBar-NavText">Uitloggen</div>
              </div>

              <div
                className={
                  this.props.state.screenState === ScreenState.User
                    ? "MenuBar-NavItem MenuBar-NavItemSelected"
                    : "MenuBar-NavItem"
                }
                onClick={(_) => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.User,
                    this.props.updateStateCallback
                  );
                }}
              >
                <div className="MenuBar-NavIcon">
                  <FiUser />
                </div>
                <div className="MenuBar-NavText">Gebruiker</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
