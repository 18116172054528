import { DocumentationTypeEnum } from "@shared/client/lib/models/DocumentationTypeEnum";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import CheckBox from "@shared/components/CheckBox";
import FileInput from "@shared/components/FileInput";
import Section from "@shared/components/Section";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { BiLinkExternal } from "react-icons/bi";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import "./DocumentationScreen.css";

interface EditDocumentationScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class DocumentationScreen extends React.Component<EditDocumentationScreenProps> {
  render() {
    return (
      <div id="DocumentationScreen">
        <BreadCrumbs
          data={[
            {
              title: "Documentatie",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.DocumentationList,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: this.props.state.editDocumentationScreen.name,
            },
          ]}
        ></BreadCrumbs>

        <Section>
          <table className="DocumentationScreen-Table">
            <tbody>
              <tr>
                <td>Naam</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Naam"
                    onChange={(newName) => {
                      this.props.stateHandler.editDocumentationForm(
                        { name: newName },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.editDocumentationScreen.name}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Beschrijving</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Beschrijving"
                    onChange={(newDescription) => {
                      this.props.stateHandler.editDocumentationForm(
                        { description: newDescription },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.editDocumentationScreen.description}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Categorie</td>
                <td>
                  {this.props.state.editDocumentationScreen
                    .documentationType ===
                  DocumentationTypeEnum.InstructionalVideo
                    ? "Instructievideo's"
                    : "Handleidingen"}
                </td>
              </tr>
              <tr>
                <td>Type</td>
                <td>
                  {this.props.state.editDocumentationScreen
                    .documentationType ===
                  DocumentationTypeEnum.InstructionalVideo
                    ? "YouTube"
                    : "PDF"}
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section>
          <table className="DocumentationScreen-Table">
            <tbody>
              <tr>
                {(() => {
                  if (
                    this.props.state.editDocumentationScreen
                      .documentationType ===
                    DocumentationTypeEnum.InstructionalVideo
                  ) {
                    return (
                      <>
                        <td>YouTube link</td>
                        <td>
                          <a
                            href={
                              this.props.state.editDocumentationScreen.videoUrl
                            }
                            rel="noreferrer"
                            target="_blank"
                            style={{ color: "black" }}
                          >
                            {this.props.state.selectedDocumentation?.videoUrl ||
                              ""}
                            <BiLinkExternal />
                          </a>
                        </td>
                      </>
                    );
                  } else {
                    return (
                      <>
                        {" "}
                        <td>PDF document*</td>
                        <td>
                          <p
                            className="DocumentationLink"
                            onClick={() => {
                              this.props.stateHandler.OpenPdfFile(
                                this.props.state.selectedDocumentation?.id || 0,
                                this.props.updateStateCallback
                              );
                            }}
                          >
                            {this.props.state.editDocumentationScreen.name}{" "}
                            <BiLinkExternal />
                          </p>
                        </td>
                      </>
                    );
                  }
                })()}
              </tr>
            </tbody>
          </table>
        </Section>
        <Section>
          <table className="DocumentationScreen-Table">
            <tbody>
              <tr>
                {(() => {
                  if (
                    this.props.state.editDocumentationScreen
                      .documentationType ===
                    DocumentationTypeEnum.InstructionalVideo
                  ) {
                    return (
                      <>
                        <td>Nieuwe YouTube link</td>
                        <td>
                          <TextInput
                            type={TextInputType.Text}
                            placeholder="YouTube link"
                            value={
                              this.props.state.editDocumentationScreen.videoUrl
                            }
                            onChange={(newVideourl) => {
                              this.props.stateHandler.editDocumentationForm(
                                { videoUrl: newVideourl },
                                this.props.updateStateCallback
                              );
                            }}
                          ></TextInput>
                        </td>
                      </>
                    );
                  } else {
                    return (
                      <>
                        {" "}
                        <td>Nieuw PDF document</td>
                        <td>
                          <FileInput
                            onChange={(event) => {
                              this.props.stateHandler.editDocumentationForm(
                                { files: event.target.files },
                                this.props.updateStateCallback
                              );
                            }}
                          ></FileInput>
                        </td>
                      </>
                    );
                  }
                })()}
              </tr>
            </tbody>
          </table>
        </Section>
        <Section>
          <SectionTitle subtitle={true}>Zichtbaar voor klant</SectionTitle>
          <table className="DocumentationScreen-Table">
            <tbody>
              {this.props.state.documentationAccess.map((item) => {
                return (
                  <tr key={item.client_id}>
                    <td>{item.client_name}</td>
                    <td>
                      <CheckBox
                        checked={item.access}
                        onClick={() => {
                          this.props.stateHandler.editDocumentationForm(
                            { access: item.client_id },
                            this.props.updateStateCallback
                          );
                        }}
                      ></CheckBox>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>
        <ButtonList
          data={[
            {
              title: "Wijzigen opslaan",
              callback: () => {
                this.props.stateHandler.saveDocumentation(
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Document verwijderen",
              callback: () => {
                this.props.stateHandler.deleteDocumentation(
                  this.props.state.selectedDocumentation?.id || 0,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.DocumentationList,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
