import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class ClientLogStateHandler {
  initClientLogScreen(
    this: AppStateHandler,
    clientId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.ClientLog;
    this.state.clientLogScreen.logs = [];
    callback(this.state);

    // Get typeProject
    this.logApi
      .apiGrexmanagerLogRetrieve({
        clientId: clientId,
        logRows: 50,
        page: 1,
      })
      .then((response) => {
        this.state.clientLogScreen.logs = response.logRows;
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);
      });
  }
}
