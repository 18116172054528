import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class EditClientStateHandler {
  edit_client_init_screen(
    this: AppStateHandler,
    clientId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.EditClient;
    this.state.samlscreen.client_id = clientId;
    // Loading client name
    this.state.clientEditScreen.loadingClientName = true;
    this.state.clientEditScreen.id = null;
    this.state.clientEditScreen.name = "";
    this.state.clientEditScreen.subdomain = "";
    callback(this.state);

    this.grexSuperAdminClientApi
      .apiGrexsuperadminClientRetrieve({ id: clientId })
      .then((response) => {
        this.state.clientEditScreen.loadingClientName = false;
        this.state.clientEditScreen.id = clientId;
        this.state.clientEditScreen.name = response.name;
        this.state.clientEditScreen.subdomain = response.schemaName;
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);

        this.state.clientEditScreen.loadingClientName = false;
        callback(this.state);
      });

    // Loading model Templates
    this.state.clientEditScreen.loadingTemplateModelList = true;
    this.state.clientEditScreen.templateModelList = [];
    callback(this.state);

    this.grexSuperAdminTemplateModelApi
      .apiGrexsuperadminClientTemplateModelList({ clientId: clientId })
      .then((response) => {
        this.state.clientEditScreen.loadingTemplateModelList = false;
        this.state.clientEditScreen.templateModelList = response;
        callback(this.state);
      })
      .catch((error) => {
        this.state.clientEditScreen.loadingTemplateModelList = false;
        callback(this.state);
        console.log(error);
      });

    // Loading report Templates
    this.state.clientEditScreen.loadingTemplateReportList = true;
    this.state.clientEditScreen.templateReportList = [];
    callback(this.state);

    this.grexSuperAdminTemplateReportApi
      .apiGrexsuperadminClientTemplateReportList({ clientId: clientId })
      .then((response) => {
        this.state.clientEditScreen.loadingTemplateReportList = false;
        this.state.clientEditScreen.templateReportList = response;
        callback(this.state);
      })
      .catch((error) => {
        this.state.clientEditScreen.loadingTemplateReportList = false;
        callback(this.state);
        console.log(error);
      });

    // Loading MapViewer settings
    this.state.clientEditScreen.loadingMapViewerSettings = true;
    this.state.clientEditScreen.mapViewerHost = "";
    this.state.clientEditScreen.mapViewerClientId = "";
    this.state.clientEditScreen.mapViewerClientSecret = "";
    this.state.clientEditScreen.mapViewerRedirectUri = "";
    this.state.clientEditScreen.mapViewerApiKeyId = "";
    this.state.clientEditScreen.mapViewerApiKey = "";
    callback(this.state);

    this.grexSuperAdminMapViewerApi
      .apiGrexsuperadminClientMapviewerSettingsRetrieve({ clientId: clientId })
      .then((response) => {
        this.state.clientEditScreen.loadingMapViewerSettings = false;
        this.state.clientEditScreen.mapViewerHost = response.host;
        this.state.clientEditScreen.mapViewerClientId = response.clientId;
        this.state.clientEditScreen.mapViewerClientSecret =
          response.clientSecret;
        this.state.clientEditScreen.mapViewerRedirectUri = response.redirectUri;
        this.state.clientEditScreen.mapViewerApiKeyId = response.apiKeyId;
        this.state.clientEditScreen.mapViewerApiKey = response.apiKey;
        callback(this.state);
      })
      .catch((error) => {
        this.state.clientEditScreen.loadingMapViewerSettings = false;
        callback(this.state);
        console.log(error);
      });
  }

  editClientUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      mapViewerHost?: string;
      mapViewerClientId?: string;
      mapViewerClientSecret?: string;
      mapViewerRedirectUri?: string;
      mapViewerApiKeyId?: string;
      mapViewerApiKey?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.mapViewerHost !== undefined) {
      this.state.clientEditScreen.mapViewerHost = newFormValues.mapViewerHost;
    }
    if (newFormValues.mapViewerClientId !== undefined) {
      this.state.clientEditScreen.mapViewerClientId =
        newFormValues.mapViewerClientId;
    }
    if (newFormValues.mapViewerClientSecret !== undefined) {
      this.state.clientEditScreen.mapViewerClientSecret =
        newFormValues.mapViewerClientSecret;
    }
    if (newFormValues.mapViewerRedirectUri !== undefined) {
      this.state.clientEditScreen.mapViewerRedirectUri =
        newFormValues.mapViewerRedirectUri;
    }
    if (newFormValues.mapViewerApiKeyId !== undefined) {
      this.state.clientEditScreen.mapViewerApiKeyId =
        newFormValues.mapViewerApiKeyId;
    }
    if (newFormValues.mapViewerApiKey !== undefined) {
      this.state.clientEditScreen.mapViewerApiKey =
        newFormValues.mapViewerApiKey;
    }
    callback(this.state);
  }

  updateMapViewerSettings(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.clientEditScreen.id) {
      if (
        this.state.clientEditScreen.mapViewerHost !== "" &&
        this.state.clientEditScreen.mapViewerClientId !== "" &&
        this.state.clientEditScreen.mapViewerClientSecret !== "" &&
        this.state.clientEditScreen.mapViewerRedirectUri !== "" &&
        this.state.clientEditScreen.mapViewerApiKeyId !== "" &&
        this.state.clientEditScreen.mapViewerApiKey !== ""
      ) {
        this.state.clientEditScreen.loadingMapViewerSettings = true;
        this.grexSuperAdminMapViewerApi
          .apiGrexsuperadminClientMapviewerSettingsUpdate({
            clientId: this.state.clientEditScreen.id,
            mapViewerClient: {
              host: this.state.clientEditScreen.mapViewerHost,
              clientId: this.state.clientEditScreen.mapViewerClientId,
              clientSecret: this.state.clientEditScreen.mapViewerClientSecret,
              redirectUri: this.state.clientEditScreen.mapViewerRedirectUri,
              apiKeyId: this.state.clientEditScreen.mapViewerApiKeyId,
              apiKey: this.state.clientEditScreen.mapViewerApiKey,
            },
          })
          .then((response) => {
            this.state.clientEditScreen.loadingMapViewerSettings = false;
            callback(this.state);
          })
          .catch((error) => {
            this.state.clientEditScreen.loadingMapViewerSettings = false;
            callback(this.state);
            console.log(error);
          });
      } else {
        // All fields must be filled in
      }
    }
  }
}
