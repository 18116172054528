import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import Section from "@shared/components/Section";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { GoCheckCircleFill, GoXCircleFill } from "react-icons/go";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import "./CreateClientScreen.css";
interface CreateClientScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class CreateClientScreen extends React.Component<CreateClientScreenProps> {
  render() {
    return (
      <div id="CreateClientScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klanten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klant aanmaken",
            },
          ]}
        ></BreadCrumbs>

        <Section disabled={false}>
          <h4>Klant Gegevens</h4>

          <table className="CreateClientScreen-Table">
            <tbody>
              <tr>
                <td>Klantnaam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Klantnaam"
                    onChange={(newValue) => {
                      this.props.stateHandler.createClientForm(
                        this.props.updateStateCallback,
                        newValue
                      );
                      this.props.stateHandler.validateClientName(
                        this.props.updateStateCallback,
                        this.props.state.newClientScreen.clientName
                      );
                    }}
                    value={this.props.state.newClientScreen.clientName}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Klant URL</td>
                <td>
                  <p>
                    {this.props.state.newClientScreen.clientName}
                    .grexmanager.nl {"  "}
                    {(() => {
                      if (
                        this.props.state.newClientScreen.availableClientName
                      ) {
                        return (
                          <>
                            <GoCheckCircleFill /> {"  "}
                            Beschikbaar
                          </>
                        );
                      } else {
                        return (
                          <>
                            <GoXCircleFill /> {"  "}
                            Niet beschikbaar
                          </>
                        );
                      }
                    })()}
                    {"  "}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <ButtonList
          data={[
            {
              title: "Klant toevoegen",
              callback: () => {
                this.props.stateHandler.createClient(
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
