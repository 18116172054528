import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import Section from "@shared/components/Section";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import "./SamlClientScreen.css";

interface SamlClientScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class SamlClientScreen extends React.Component<SamlClientScreenProps> {
  render() {
    return (
      <div id="SamlClientScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klanten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: this.props.state.clientEditScreen.name,
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.EditClient,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "SAML instellingen",
            },
          ]}
        ></BreadCrumbs>
        {(() => {
          if (this.props.state.samlscreen.new_saml_form_error !== "") {
            return (
              <Alert
                Error={true}
                Message={this.props.state.samlscreen.new_saml_form_error}
              ></Alert>
            );
          }
          if (this.props.state.samlscreen.new_saml_form_info !== "") {
            return (
              <Alert
                Error={false}
                Message={this.props.state.samlscreen.new_saml_form_info}
              ></Alert>
            );
          }
        })()}
        {/* if saml is enabled show the saml data */}
        {(() => {
          if (this.props.state.samlscreen.samlEnabled) {
            return (
              <>
                <Section disabled={false}>
                  <SectionTitle>SAML Gegevens</SectionTitle>
                  <table className="SamlClientScreen-Table">
                    <tbody>
                      <tr>
                        <td>IDP entity id</td>
                        <td>{this.props.state.samlscreen.samlEntityId}</td>
                      </tr>
                      <tr>
                        <td>IDP SSO</td>
                        <td>{this.props.state.samlscreen.samlSSOService}</td>
                      </tr>
                      <tr>
                        <td>IDP SLS</td>
                        <td>{this.props.state.samlscreen.samlSLService}</td>
                      </tr>
                      <tr>
                        <td>SP entity id</td>
                        <td>
                          {this.props.state.samlscreen.saml_grex_sp_EntityId}
                        </td>
                      </tr>
                      <tr>
                        <td>SP ACS</td>
                        <td>{this.props.state.samlscreen.saml_grex_sp_ACS}</td>
                      </tr>
                      <tr>
                        <td>SP SLS</td>
                        <td>{this.props.state.samlscreen.saml_grex_sp_sls}</td>
                      </tr>
                    </tbody>
                  </table>
                </Section>
              </>
            );
          }
        })()}
        <Section disabled={false}>
          <SectionTitle> SAML instellingen</SectionTitle>
          <table className="SamlClientScreen-Table">
            <tbody>
              <tr>
                <td>IDP entity id</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Entity ID"
                    value={this.props.state.samlscreen.new_samlEntityId}
                    onChange={(newValue) => {
                      this.props.stateHandler.samlForm(
                        { new_samlEntityId: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>IDP SSO</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Single Sign On service"
                    value={this.props.state.samlscreen.new_samlSSOService}
                    onChange={(newValue) => {
                      this.props.stateHandler.samlForm(
                        { new_samlSSOService: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>IDP SLS</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Single Logout Service"
                    value={this.props.state.samlscreen.new_samlSLService}
                    onChange={(newValue) => {
                      this.props.stateHandler.samlForm(
                        { new_samlSLService: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>IDP Certificate</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Certificate"
                    value={this.props.state.samlscreen.new_samlCertificate}
                    onChange={(newValue) => {
                      this.props.stateHandler.samlForm(
                        { new_samlCertificate: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>
        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.saveSaml(
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.EditClient,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
