import { StateEnum } from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class AddReportTemplateStateHandler {
  initAddReportTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.AddReportTemplate;
    callback(this.state);
  }

  updateAddReportTemplateScreen(
    this: AppStateHandler,
    newFormValues: {
      displayName?: string;
      description?: string;
      nModelsMin?: string;
      nModelsMax?: string;
      files?: FileList | null;
      status?: StateEnum;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.displayName !== undefined) {
      this.state.addReportTemplateScreen.displayName =
        newFormValues.displayName;
    }
    if (newFormValues.description !== undefined) {
      this.state.addReportTemplateScreen.description =
        newFormValues.description;
    }
    if (newFormValues.nModelsMin !== undefined) {
      this.state.addReportTemplateScreen.nModelsMin = newFormValues.nModelsMin;
    }
    if (newFormValues.nModelsMax !== undefined) {
      this.state.addReportTemplateScreen.nModelsMax = newFormValues.nModelsMax;
    }
    if (newFormValues.status !== undefined) {
      this.state.addReportTemplateScreen.status = newFormValues.status;
    }
    if (newFormValues.files !== undefined) {
      if (newFormValues.files !== null) {
        this.state.addReportTemplateScreen.file = newFormValues.files[0];
      }
    }
    callback(this.state);
  }

  saveAddReportTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.clientEditScreen.id) {
      if (this.state.addReportTemplateScreen.displayName !== "") {
        if (
          isNaN(parseInt(this.state.addReportTemplateScreen.nModelsMin)) ||
          isNaN(parseInt(this.state.addReportTemplateScreen.nModelsMax))
        ) {
          this.state.addReportTemplateScreen.formError =
            "Min. en max. rekenmodellen moeten gehele getallen zijn.";
          callback(this.state);
          setTimeout(() => {
            this.state.addReportTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        } else {
          if (parseInt(this.state.addReportTemplateScreen.nModelsMin) >= 1) {
            if (
              parseInt(this.state.addReportTemplateScreen.nModelsMax) <=
                2147483647 &&
              parseInt(this.state.addReportTemplateScreen.nModelsMax) >=
                parseInt(this.state.addReportTemplateScreen.nModelsMin)
            ) {
              if (this.state.addReportTemplateScreen.file) {
                this.state.loading = false;
                callback(this.state);
                var formData = new FormData();
                formData.append(
                  "name",
                  `${this.state.addReportTemplateScreen.displayName}`
                );
                formData.append(
                  "description",
                  `${this.state.addReportTemplateScreen.description}`
                );
                formData.append(
                  "n_models_min",
                  `${parseInt(this.state.addReportTemplateScreen.nModelsMin)}`
                );
                formData.append(
                  "n_models_max",
                  `${parseInt(this.state.addReportTemplateScreen.nModelsMax)}`
                );
                formData.append(
                  "state",
                  `${this.state.addReportTemplateScreen.status}`
                );
                formData.append(
                  "file",
                  this.state.addReportTemplateScreen.file
                );
                formData.append("active", "True");

                const url = `${this.basePath()}/api/grexsuperadmin/client/${
                  this.state.clientEditScreen.id
                }/template-report/`;
                const xhr = new XMLHttpRequest();
                xhr.onreadystatechange = (e) => {
                  if (xhr.readyState === 4) {
                    if (xhr.status === 201) {
                      this.state.loading = false;
                      callback(this.state);
                      if (this.state.clientEditScreen.id) {
                        this.edit_client_init_screen(
                          this.state.clientEditScreen.id,
                          callback
                        );
                      }
                    } else {
                      this.state.addReportTemplateScreen.formError =
                        "Upload niet geslaagd.";
                      this.state.loading = false;
                      callback(this.state);
                      setTimeout(() => {
                        this.state.addReportTemplateScreen.formError = "";
                        callback(this.state);
                      }, 2000);
                    }
                  }
                };
                xhr.open("POST", url, true);
                xhr.setRequestHeader(
                  "Authorization",
                  `Bearer ${this.state.currentUser.accessToken || ""}`
                );
                xhr.send(formData);
              } else {
                this.state.addReportTemplateScreen.formError =
                  "Selecteer een bestand.";
                callback(this.state);
                setTimeout(() => {
                  this.state.addReportTemplateScreen.formError = "";
                  callback(this.state);
                }, 2000);
              }
            } else {
              this.state.addReportTemplateScreen.formError =
                "Het maximumaantal rekenmodellen moet kleiner dan of gelijk aan 2.147.483.647 zijn.";
              callback(this.state);
              setTimeout(() => {
                this.state.addReportTemplateScreen.formError = "";
                callback(this.state);
              }, 2000);
            }
          } else {
            this.state.addReportTemplateScreen.formError =
              "Het minimumaantal rekenmodellen moet groter dan of gelijk aan 1 zijn.";
            callback(this.state);
            setTimeout(() => {
              this.state.addReportTemplateScreen.formError = "";
              callback(this.state);
            }, 2000);
          }
        }
      } else {
        this.state.addReportTemplateScreen.formError = "Vul een naam in.";
        callback(this.state);
        setTimeout(() => {
          this.state.addReportTemplateScreen.formError = "";
          callback(this.state);
        }, 2000);
      }
    }
  }
}
