import {
  Configuration,
  DocumentationApi,
  GrexSuperAdminMapViewerApi,
} from "@shared/client/lib";
import { AuthApi } from "@shared/client/lib/apis/AuthApi";
import { CategoryAttachmentApi } from "@shared/client/lib/apis/CategoryAttachmentApi";
import { GrexSuperAdminClientApi } from "@shared/client/lib/apis/GrexSuperAdminClientApi";
import { GrexSuperAdminDocumentationApi } from "@shared/client/lib/apis/GrexSuperAdminDocumentationApi";
import { GrexSuperAdminSAMLApi } from "@shared/client/lib/apis/GrexSuperAdminSAMLApi";
import { GrexSuperAdminTemplateModelApi } from "@shared/client/lib/apis/GrexSuperAdminTemplateModelApi";
import { GrexSuperAdminTemplateReportApi } from "@shared/client/lib/apis/GrexSuperAdminTemplateReportApi";
import { GrexUserAdminApi } from "@shared/client/lib/apis/GrexUserAdminApi";
import { GrexUserMeApi } from "@shared/client/lib/apis/GrexUserMeApi";
import { LogApi } from "@shared/client/lib/apis/LogApi";
import { ModelApi } from "@shared/client/lib/apis/ModelApi";
import { PhaseApi } from "@shared/client/lib/apis/PhaseApi";
import { ProjectApi } from "@shared/client/lib/apis/ProjectApi";
import { SubregionApi } from "@shared/client/lib/apis/SubregionApi";
import { TemplateModelApi } from "@shared/client/lib/apis/TemplateModelApi";
import { TokenApi } from "@shared/client/lib/apis/TokenApi";
import { TypeProjectApi } from "@shared/client/lib/apis/TypeProjectApi";
import { VersionModelApi } from "@shared/client/lib/apis/VersionModelApi";
import { jwtDecode } from "jwt-decode";
import { initialAppStateString } from "./InitialAppState";
import { AppStateType, ScreenState } from "./Types";
import { LoginMfaStateHandler } from "./screens/auth/LoginMfaStateHandler";
import { LoginStateHandler } from "./screens/auth/LoginStateHandler";
import { AddModelTemplateStateHandler } from "./screens/clients/AddModelTemplateStateHandler";
import { AddReportTemplateStateHandler } from "./screens/clients/AddReportTemplateStateHandler";
import { ClientLogStateHandler } from "./screens/clients/ClientLogStateHandler";
import { ClientsStateHandler } from "./screens/clients/ClientsStateHandler";
import { CreateClientStateHandler } from "./screens/clients/CreateClientStateHandler";
import { EditClientStateHandler } from "./screens/clients/EditClientStateHandler";
import { EditModelTemplateStateHandler } from "./screens/clients/EditModelTemplateStateHandler";
import { EditReportTemplateStateHandler } from "./screens/clients/EditReportTemplateStateHandler";
import { EditSubregionStateHandler } from "./screens/clients/EditSubregionStateHandler";
import { SamlClientStateHandler } from "./screens/clients/SamlClientStateHandler";
import { DocumentationListStateHandler } from "./screens/docs/DocumentationListStateHandler";
import { DocumentationStateHandler } from "./screens/docs/DocumentationStateHandler";
import { NewDocumentationStateHandler } from "./screens/docs/NewDocumentationStateHandler";
import { LogStateHandler } from "./screens/log/LogStateHandler";
import { SetupMfaStateHandler } from "./screens/user/SetupMfaStateHandler";
import { UserStateHandler } from "./screens/user/UserStateHandler";
export class AppStateHandler
  implements
    LoginStateHandler,
    ClientsStateHandler,
    LogStateHandler,
    UserStateHandler,
    SetupMfaStateHandler
{
  public state: AppStateType = JSON.parse(initialAppStateString);

  public basePath: () => string = () => {
    let basePath: string = window.location.href;
    basePath = basePath.replace("localhost:4000", "localhost:8000");
    // Remove trailing slash if any
    if (basePath.substring(basePath.length - 1) === "/") {
      basePath = basePath.substring(0, basePath.length - 1);
    }
    return basePath;
  };

  // API
  private apiConfiguration = new Configuration({
    basePath: this.basePath(),
    accessToken: () => {
      return this.state.currentUser.accessToken || "";
    },
  });
  public authApi = new AuthApi(this.apiConfiguration);
  public tokenApi = new TokenApi(this.apiConfiguration);
  public typeProjectApi = new TypeProjectApi(this.apiConfiguration);
  public subregionApi = new SubregionApi(this.apiConfiguration);
  public phaseApi = new PhaseApi(this.apiConfiguration);
  public projectApi = new ProjectApi(this.apiConfiguration);
  public versionModelApi = new VersionModelApi(this.apiConfiguration);
  public modelApi = new ModelApi(this.apiConfiguration);
  public templateModelApi = new TemplateModelApi(this.apiConfiguration);
  public logApi = new LogApi(this.apiConfiguration);
  public categoryAttachmentApi = new CategoryAttachmentApi(
    this.apiConfiguration
  );
  public grexUserAdminApi = new GrexUserAdminApi(this.apiConfiguration);
  public grexUserMeApi = new GrexUserMeApi(this.apiConfiguration);
  public grexSuperAdminClientApi = new GrexSuperAdminClientApi(
    this.apiConfiguration
  );
  public grexSuperAdminSAMLApi = new GrexSuperAdminSAMLApi(
    this.apiConfiguration
  );
  public grexSuperAdminTemplateModelApi = new GrexSuperAdminTemplateModelApi(
    this.apiConfiguration
  );
  public grexSuperAdminTemplateReportApi = new GrexSuperAdminTemplateReportApi(
    this.apiConfiguration
  );
  public grexSuperAdminDocumentationApi = new GrexSuperAdminDocumentationApi(
    this.apiConfiguration
  );
  public grexManagerdocumentationApi = new DocumentationApi(
    this.apiConfiguration
  );
  public grexSuperAdminMapViewerApi = new GrexSuperAdminMapViewerApi(
    this.apiConfiguration
  );

  public getState = () => {
    return this.state;
  };

  public initApp = (callback: (newState: AppStateType) => void) => {
    this.tokenApi
      .apiGrexmanagerTokenRefreshRetrieve()
      .then((response) => {
        if (response.access !== null) {
          this.setAccessToken(response.access, callback);
          this.state.loggedIn = true;
          this.changeScreen(ScreenState.Clients, callback);
        }
        //else => Stay signed out
      })
      .catch((error) => {
        console.log(error);
        // Stay signed out
      });
  };

  public logout = (callback: (newState: AppStateType) => void) => {
    this.state = JSON.parse(initialAppStateString);
    callback(this.state);
    this.authApi
      .apiGrexmanagerUserLogoutCreate()
      .then((response) => {
        console.log("Logged out");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  public changeScreen = (
    newScreenState: ScreenState,
    callback: (newState: AppStateType) => void
  ) => {
    console.log("changeScreen", newScreenState);
    if (newScreenState === ScreenState.Clients) {
      this.clients_init_screen(callback);
    } else if (newScreenState === ScreenState.EditClient) {
      this.state.screenState = ScreenState.EditClient;
      callback(this.state);
    } else if (newScreenState === ScreenState.Logs) {
      this.initLogScreen(callback);
    } else if (newScreenState === ScreenState.CreateClient) {
      this.validateClientName(callback, this.state.newClientScreen.clientName);
      this.state.screenState = ScreenState.CreateClient;
      callback(this.state);
    } else if (newScreenState === ScreenState.User) {
      this.loadUserinfo(callback);
    } else if (newScreenState === ScreenState.AddModelTemplate) {
      this.initAddModelTemplateScreen(callback);
    } else if (newScreenState === ScreenState.AddReportTemplate) {
      this.initAddReportTemplateScreen(callback);
    } else if (newScreenState === ScreenState.DocumentationList) {
      this.initDocumentationScreen(callback);
    } else if (newScreenState === ScreenState.EditDocumentation) {
      this.loadDocumentationinfo(callback);
    } else if (newScreenState === ScreenState.NewDocumentation) {
      this.loadNewDocumentationinfo(callback);
    } else if (newScreenState === ScreenState.EditSubregion) {
      this.initEditSubregionScreen(callback);
    } else if (newScreenState === ScreenState.SamlClient) {
      this.loadSamlForm(callback);
      // this.initEditSubregionScreen(callback);
    } else {
      this.state.screenState = newScreenState;
      callback(this.state);
    }
  };

  public setAccessToken = (
    accessToken: string,
    callback: (newState: AppStateType) => void
  ) => {
    const secondsMargin = 30; // 30 seconds before token expires a refresh request will be made
    this.state.currentUser.accessToken = accessToken;

    // Decode the access token
    const decoded = jwtDecode(this.state.currentUser.accessToken) as {
      [key: string]: any;
    };
    if ("exp" in decoded) {
      const currentUnixTime = Math.floor(Date.now() / 1000);
      const secondsLeft = decoded.exp - currentUnixTime;
      const refreshInMs = Math.max((secondsLeft - secondsMargin) * 1000, 1);

      // Set a timer to refresh the access token
      setTimeout(() => {
        this.tokenApi
          .apiGrexmanagerTokenRefreshRetrieve()
          .then((response) => {
            if (response.access !== null) {
              this.setAccessToken(response.access, callback);
            } else {
              // sign out
              this.state = JSON.parse(initialAppStateString);
              callback(this.state);
            }
          })
          .catch((error) => {
            console.log(error);
            //sign out
            this.state = JSON.parse(initialAppStateString);
            callback(this.state);
          });
      }, refreshInMs);
    }
  };

  // Login screen
  login = LoginStateHandler.prototype.login;

  // MFA screen
  check_mfa_code = LoginMfaStateHandler.prototype.check_mfa_code;

  // Clients screen
  clients_init_screen = ClientsStateHandler.prototype.clients_init_screen;
  edit_client_button_clicked =
    ClientsStateHandler.prototype.edit_client_button_clicked;
  add_client_button_clicked =
    ClientsStateHandler.prototype.add_client_button_clicked;

  // Edit clients screen
  edit_client_init_screen =
    EditClientStateHandler.prototype.edit_client_init_screen;
  editClientUpdateForm = EditClientStateHandler.prototype.editClientUpdateForm;
  // saveSamlSettings = EditClientStateHandler.prototype.clientSamlSettings;
  updateMapViewerSettings =
    EditClientStateHandler.prototype.updateMapViewerSettings;

  // Log screen
  initLogScreen = LogStateHandler.prototype.initLogScreen;

  // client logs
  initClientLogScreen = ClientLogStateHandler.prototype.initClientLogScreen;

  // User screen
  loadUserinfo = UserStateHandler.prototype.loadUserinfo;
  updateUserForm = UserStateHandler.prototype.updateUserForm;
  updateUser = UserStateHandler.prototype.updateUser;
  changePasswordForm = UserStateHandler.prototype.changePasswordForm;
  vlaidateNewPasswordRepeat =
    UserStateHandler.prototype.vlaidateNewPasswordRepeat;
  changePassword = UserStateHandler.prototype.changePassword;
  disableMFA = UserStateHandler.prototype.disableMFA;

  // SetupMfa
  loadMfaQrCode = SetupMfaStateHandler.prototype.loadMfaQrCode;
  VerifyMfaCode = SetupMfaStateHandler.prototype.VerifyMfaCode;

  // CreateClient
  createClientForm = CreateClientStateHandler.prototype.createClientForm;
  validateClientName = CreateClientStateHandler.prototype.validateClientName;
  createClient = CreateClientStateHandler.prototype.createClient;

  // AddModelTemplate
  initAddModelTemplateScreen =
    AddModelTemplateStateHandler.prototype.initAddModelTemplateScreen;
  updateAddModelTemplateScreen =
    AddModelTemplateStateHandler.prototype.updateAddModelTemplateScreen;
  saveAddModelTemplateScreen =
    AddModelTemplateStateHandler.prototype.saveAddModelTemplateScreen;

  // EditModelTemplate
  initEditModelTemplateScreen =
    EditModelTemplateStateHandler.prototype.initEditModelTemplateScreen;
  saveUpdateEditModelTemplateScreen =
    EditModelTemplateStateHandler.prototype.saveUpdateEditModelTemplateScreen;
  updateEditModelTemplateScreen =
    EditModelTemplateStateHandler.prototype.updateEditModelTemplateScreen;
  saveEditModelTemplateScreen =
    EditModelTemplateStateHandler.prototype.saveEditModelTemplateScreen;
  activateModelTemplateScreen =
    EditModelTemplateStateHandler.prototype.activateModelTemplateScreen;
  deleteEditModelTemplateScreen =
    EditModelTemplateStateHandler.prototype.deleteEditModelTemplateScreen;

  // AddReportTemplate
  initAddReportTemplateScreen =
    AddReportTemplateStateHandler.prototype.initAddReportTemplateScreen;
  updateAddReportTemplateScreen =
    AddReportTemplateStateHandler.prototype.updateAddReportTemplateScreen;
  saveAddReportTemplateScreen =
    AddReportTemplateStateHandler.prototype.saveAddReportTemplateScreen;

  // EditReportTemplate
  initEditReportTemplateScreen =
    EditReportTemplateStateHandler.prototype.initEditReportTemplateScreen;
  saveUpdateEditReportTemplateScreen =
    EditReportTemplateStateHandler.prototype.saveUpdateEditReportTemplateScreen;
  updateEditReportTemplateScreen =
    EditReportTemplateStateHandler.prototype.updateEditReportTemplateScreen;
  saveEditReportTemplateScreen =
    EditReportTemplateStateHandler.prototype.saveEditReportTemplateScreen;
  activateReportTemplateScreen =
    EditReportTemplateStateHandler.prototype.activateReportTemplateScreen;
  deleteEditReportTemplateScreen =
    EditReportTemplateStateHandler.prototype.deleteEditReportTemplateScreen;

  initDocumentationScreen =
    DocumentationListStateHandler.prototype.initDocumentationScreen;
  selectDocumentation =
    DocumentationListStateHandler.prototype.selectDocumentation;
  loadDocumentationinfo =
    DocumentationStateHandler.prototype.loadDocumentationinfo;
  loadNewDocumentationinfo =
    NewDocumentationStateHandler.prototype.loadNewDocumentationinfo;
  newDocumentationForm =
    NewDocumentationStateHandler.prototype.newDocumentationForm;
  valisateNewDocumentationForm =
    NewDocumentationStateHandler.prototype.validateNewDocumentationForm;
  createDocumentation =
    NewDocumentationStateHandler.prototype.createDocumentation;
  OpenPdfFile = DocumentationStateHandler.prototype.OpenPdfFile;
  deleteDocumentation = DocumentationStateHandler.prototype.deleteDocumentation;
  editDocumentationForm =
    DocumentationStateHandler.prototype.editDocumentationForm;
  validateEditDocumentationForm =
    DocumentationStateHandler.prototype.validateEditDocumentationForm;
  saveDocumentation = DocumentationStateHandler.prototype.saveDocumentation;

  // EditSubregion
  initEditSubregionScreen =
    EditSubregionStateHandler.prototype.initEditSubregionScreen;
  updateEditSubregionScreen =
    EditSubregionStateHandler.prototype.updateEditSubregionScreen;
  saveEditSubregionScreen =
    EditSubregionStateHandler.prototype.saveEditSubregionScreen;

  // SAML client
  loadSamlForm = SamlClientStateHandler.prototype.loadSamlForm;
  samlForm = SamlClientStateHandler.prototype.samlForm;
  vlaidateSamlForm = SamlClientStateHandler.prototype.vlaidateSamlForm;
  saveSaml = SamlClientStateHandler.prototype.saveSaml;
}
