import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class LoginMfaStateHandler {
  check_mfa_code(
    this: AppStateHandler,
    mfa_code: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.login2fa.mfaInput = mfa_code;
    callback(this.state);

    if (mfa_code.length === 6) {
      this.authApi
        .apiGrexmanagerUserLoginMfaCreate({
          login2fa: {
            email: this.state.login2fa.useremail,
            mfaCode: mfa_code,
          },
        })
        .then((response: any) => {
          if (response.access) {
            this.state.login2fa.mfaInput = "";
            this.setAccessToken(response.access, callback);
            this.state.loggedIn = true;
            this.state.mfaRequired = false;
            this.state.login2fa.errorMessage = "";
            callback(this.state);
            this.changeScreen(ScreenState.Clients, callback);
          } else {
            this.state.login2fa.mfaInput = "";
            this.state.login2fa.errorMessage = "Ongeldige code!";
            callback(this.state);
          }
        })
        .catch((error) => {
          this.state.login2fa.mfaInput = "";
          this.state.login2fa.errorMessage = "Ongeldige code!";
          callback(this.state);
        });
    } else {
      this.state.login2fa.errorMessage = "";
      callback(this.state);
    }
  }
}
