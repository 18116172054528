import { DocumentationSuperAdmin } from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class DocumentationListStateHandler {
  initDocumentationScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.DocumentationList;
    callback(this.state);

    // Get typeProject
    this.grexSuperAdminDocumentationApi
      .apiGrexsuperadminDocumentationList()
      .then((documentations: DocumentationSuperAdmin[]) => {
        this.state.documentationList = documentations;
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  selectDocumentation(
    this: AppStateHandler,
    documentation: DocumentationSuperAdmin,
    callback: (newState: AppStateType) => void
  ) {
    this.state.selectedDocumentation = documentation;
    this.changeScreen(ScreenState.EditDocumentation, callback);
  }
}
