import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class LoginStateHandler {
  login(
    this: AppStateHandler,
    email: string,
    password: string,
    callback: (newState: AppStateType) => void
  ) {
    this.authApi
      .apiGrexmanagerUserLoginCreate({
        login: {
          email: email,
          password: password,
        },
      })
      .then((response) => {
        if (response.access !== null) {
          this.setAccessToken(response.access, callback);
          this.state.loggedIn = true;
          this.changeScreen(ScreenState.Clients, callback);
        } else if (response.mfa === true) {
          this.state.login2fa.useremail = email;
          this.state.loggedIn = true;
          this.state.mfaRequired = true;
          callback(this.state);
        }
      })
      .catch((error) => {
        console.log(error);

        this.state.LoginScreen.login_error =
          "Combinatie van e-mailadres en wachtwoord is onjuist.";
        console.log(error);
        callback(this.state);
      });
  }
}
