import { ApiGrexsuperadminClientCreateRequest } from "@shared/client/lib/apis/GrexSuperAdminClientApi";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class CreateClientStateHandler {
  createClientForm(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void,
    clientname: string
  ) {
    this.state.screenState = ScreenState.CreateClient;

    this.state.newClientScreen.clientName = clientname.toLowerCase();

    callback(this.state);
  }

  validateClientName(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void,
    clientName: string
  ) {
    this.grexSuperAdminClientApi
      .apiGrexsuperadminClientCheckRetrieve({
        name: clientName,
      })
      .then((response) => {
        this.state.newClientScreen.availableClientName = response.available;
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  createClient(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    const request: ApiGrexsuperadminClientCreateRequest = {
      clientCreateUpdateDelete: {
        name: this.state.newClientScreen.clientName,
      },
    };
    this.state.loading = true;
    callback(this.state);
    this.grexSuperAdminClientApi
      .apiGrexsuperadminClientCreate(request)
      .then((response) => {
        this.state.loading = false;
        this.state.newClientScreen.message =
          this.state.newClientScreen.clientName + "is aangemaakt!";
        setTimeout(() => {
          this.state.newClientScreen.message = "";
          callback(this.state);
        }, 2000);

        this.changeScreen(ScreenState.Clients, callback);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
