import BreadCrumbs from "@shared/components/BreadCrumbs";
import Button from "@shared/components/Button";
import Section from "@shared/components/Section";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import "./EditClientScreen.css";

interface EditClientScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class EditClientScreen extends React.Component<EditClientScreenProps> {
  render() {
    return (
      <div id="EditClientScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klanten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klant bewerken",
            },
          ]}
        ></BreadCrumbs>

        <Section
          disabled={
            this.props.state.clientEditScreen.loadingClientName ||
            this.props.state.clientEditScreen.id === null
          }
        >
          <table className="EditClientScreen-Table">
            <tbody>
              <tr>
                <td>Klantnaam</td>
                <td>{this.props.state.clientEditScreen.name}</td>
              </tr>
              <tr>
                <td>Subdomein</td>
                <td>{this.props.state.clientEditScreen.subdomain}</td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section
          disabled={this.props.state.clientEditScreen.loadingMapViewerSettings}
        >
          <SectionTitle subtitle={true}>MapViewer Settings</SectionTitle>
          <table className="EditClientScreen-Table">
            <tbody>
              <tr>
                <td>Host</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Host"
                    onChange={(newValue) => {
                      this.props.stateHandler.editClientUpdateForm(
                        { mapViewerHost: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.clientEditScreen.mapViewerHost}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Client ID</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Client ID"
                    onChange={(newValue) => {
                      this.props.stateHandler.editClientUpdateForm(
                        { mapViewerClientId: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.clientEditScreen.mapViewerClientId}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Client secret</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Client secret"
                    onChange={(newValue) => {
                      this.props.stateHandler.editClientUpdateForm(
                        { mapViewerClientSecret: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={
                      this.props.state.clientEditScreen.mapViewerClientSecret
                    }
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Redirect URI</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Redirect URI"
                    onChange={(newValue) => {
                      this.props.stateHandler.editClientUpdateForm(
                        { mapViewerRedirectUri: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={
                      this.props.state.clientEditScreen.mapViewerRedirectUri
                    }
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>API Key ID</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="API Key ID"
                    onChange={(newValue) => {
                      this.props.stateHandler.editClientUpdateForm(
                        { mapViewerApiKeyId: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.clientEditScreen.mapViewerApiKeyId}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>API Key</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="API Key"
                    onChange={(newValue) => {
                      this.props.stateHandler.editClientUpdateForm(
                        { mapViewerApiKey: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.clientEditScreen.mapViewerApiKey}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Button
                    onClick={() => {
                      this.props.stateHandler.updateMapViewerSettings(
                        this.props.updateStateCallback
                      );
                    }}
                  >
                    Wijzigingen opslaan
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section
          disabled={
            this.props.state.clientEditScreen.loadingClientName ||
            this.props.state.clientEditScreen.id === null
          }
        >
          <table className="EditClientScreen-Table">
            <tbody>
              <tr>
                <td>Logs</td>
                <td>
                  <Button
                    onClick={() => {
                      if (
                        this.props.state.clientEditScreen.id &&
                        !this.props.state.clientEditScreen.loadingClientName
                      ) {
                        this.props.stateHandler.initClientLogScreen(
                          this.props.state.clientEditScreen.id,
                          this.props.updateStateCallback
                        );
                      }
                    }}
                  >
                    Logs bekijken
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section disabled={false}>
          <table className="EditClientScreen-Table">
            <tbody>
              <tr>
                <td>SAML-configuratie</td>
                <td>
                  <Button
                    onClick={() => {
                      this.props.stateHandler.changeScreen(
                        ScreenState.SamlClient,
                        this.props.updateStateCallback
                      );
                    }}
                  >
                    Instellingen
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section
          disabled={this.props.state.clientEditScreen.loadingTemplateModelList}
        >
          <SectionTitle subtitle={true}>
            Sjablonen voor rekenmodellen
          </SectionTitle>
          <table className="EditClientScreen-Table">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Beschrijving</th>
              </tr>
            </thead>
            <tbody>
              {this.props.state.clientEditScreen.templateModelList.map(
                (item) => {
                  return (
                    <tr
                      key={item.id}
                      onClick={() => {
                        this.props.stateHandler.initEditModelTemplateScreen(
                          item.id,
                          this.props.updateStateCallback
                        );
                      }}
                    >
                      <td>{item.name}</td>
                      <td>
                        {item.description === "" ? "-" : item.description}
                      </td>
                    </tr>
                  );
                }
              )}
              <tr>
                <td></td>
                <td>
                  <Button
                    onClick={() => {
                      this.props.stateHandler.changeScreen(
                        ScreenState.AddModelTemplate,
                        this.props.updateStateCallback
                      );
                    }}
                  >
                    Sjabloon toevoegen
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section
          disabled={this.props.state.clientEditScreen.loadingTemplateReportList}
        >
          <SectionTitle subtitle={true}>
            Sjablonen voor rapportages
          </SectionTitle>
          <table className="EditClientScreen-Table">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Beschrijving</th>
              </tr>
            </thead>
            <tbody>
              {this.props.state.clientEditScreen.templateReportList.map(
                (item) => {
                  return (
                    <tr
                      key={item.id}
                      onClick={() => {
                        this.props.stateHandler.initEditReportTemplateScreen(
                          item.id,
                          this.props.updateStateCallback
                        );
                      }}
                    >
                      <td>{item.name}</td>
                      <td>
                        {item.description === "" ? "-" : item.description}
                      </td>
                    </tr>
                  );
                }
              )}
              <tr>
                <td></td>
                <td>
                  <Button
                    onClick={() => {
                      this.props.stateHandler.changeScreen(
                        ScreenState.AddReportTemplate,
                        this.props.updateStateCallback
                      );
                    }}
                  >
                    Sjabloon toevoegen
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>
      </div>
    );
  }
}
