import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class EditModelTemplateStateHandler {
  initEditModelTemplateScreen(
    this: AppStateHandler,
    templateModelId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.EditModelTemplate;
    this.state.editModelTemplateScreen.id = null;
    this.state.editModelTemplateScreen.displayName = "";
    this.state.editModelTemplateScreen.description = "";
    this.state.editModelTemplateScreen.history = [];
    callback(this.state);

    if (this.state.clientEditScreen.id) {
      this.grexSuperAdminTemplateModelApi
        .apiGrexsuperadminClientTemplateModelRetrieve({
          clientId: this.state.clientEditScreen.id,
          templateModelId: templateModelId,
        })
        .then((response) => {
          this.state.editModelTemplateScreen.id = response.id;
          this.state.editModelTemplateScreen.displayName = response.name;
          this.state.editModelTemplateScreen.description = response.description;
          this.state.editModelTemplateScreen.history = response.history;
          callback(this.state);
        })
        .catch((error) => {
          console.log(error);
          this.state.editModelTemplateScreen.formError =
            "Error tijdens laden scherm.";
          callback(this.state);
          setTimeout(() => {
            this.state.editModelTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        });
    }
  }

  updateEditModelTemplateScreen(
    this: AppStateHandler,
    newFormValues: {
      displayName?: string;
      description?: string;
      datasheetName?: string;
      files?: FileList | null;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.displayName !== undefined) {
      this.state.editModelTemplateScreen.displayName =
        newFormValues.displayName;
    }
    if (newFormValues.description !== undefined) {
      this.state.editModelTemplateScreen.description =
        newFormValues.description;
    }
    if (newFormValues.datasheetName !== undefined) {
      this.state.editModelTemplateScreen.datasheetName =
        newFormValues.datasheetName;
    }
    if (newFormValues.files !== undefined) {
      if (newFormValues.files !== null) {
        this.state.editModelTemplateScreen.file = newFormValues.files[0];
      }
    }
    callback(this.state);
  }

  saveUpdateEditModelTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.editModelTemplateScreen.id &&
      this.state.clientEditScreen.id &&
      this.state.editModelTemplateScreen.history.length > 0
    ) {
      if (this.state.editModelTemplateScreen.displayName !== "") {
        let activeTemplate: number | null = null;
        this.state.editModelTemplateScreen.history.forEach((template) => {
          if (template.active === true) {
            activeTemplate = template.id;
          }
        });

        if (activeTemplate) {
          this.grexSuperAdminTemplateModelApi
            .apiGrexsuperadminClientTemplateModelUpdate({
              clientId: this.state.clientEditScreen.id,
              templateModelId: this.state.editModelTemplateScreen.id,
              clientUpdateTemplateModelRequest: {
                name: this.state.editModelTemplateScreen.displayName,
                description: this.state.editModelTemplateScreen.description,
                active: activeTemplate,
              },
            })
            .then((response) => {
              console.log(response);
              this.state.editModelTemplateScreen.successMessage = "Opgeslagen";
              callback(this.state);
              setTimeout(() => {
                this.state.editModelTemplateScreen.successMessage = "";
                callback(this.state);
              }, 2000);
            })
            .catch((error) => {
              console.log(error);
              this.state.editModelTemplateScreen.formError =
                "Error tijdens opslaan.";
              callback(this.state);
              setTimeout(() => {
                this.state.editModelTemplateScreen.formError = "";
                callback(this.state);
              }, 2000);
            });
        }
      } else {
        this.state.editReportTemplateScreen.formError =
          "Vul een zichtbare naam in.";
        callback(this.state);
        setTimeout(() => {
          this.state.editModelTemplateScreen.formError = "";
          callback(this.state);
        }, 2000);
      }
    }
  }

  saveEditModelTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.clientEditScreen.id &&
      this.state.editModelTemplateScreen.id
    ) {
      if (this.state.editModelTemplateScreen.datasheetName !== "") {
        if (this.state.editModelTemplateScreen.file) {
          this.state.loading = false;
          callback(this.state);
          var formData = new FormData();
          formData.append(
            "datasheet_name",
            `${this.state.editModelTemplateScreen.datasheetName}`
          );
          formData.append("file", this.state.editModelTemplateScreen.file);
          formData.append("active", "True");

          const url = `${this.basePath()}/api/grexsuperadmin/client/${
            this.state.clientEditScreen.id
          }/template-model/${this.state.editModelTemplateScreen.id}/`;
          const xhr = new XMLHttpRequest();
          xhr.onreadystatechange = (e) => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                this.state.loading = false;
                this.state.editModelTemplateScreen.datasheetName = "data";
                this.state.editModelTemplateScreen.file = null;
                this.state.editModelTemplateScreen.successMessage =
                  "Upload geslaagd";
                callback(this.state);
                setTimeout(() => {
                  this.state.editModelTemplateScreen.successMessage = "";
                  callback(this.state);
                }, 2000);

                // Reload list
                if (
                  this.state.editModelTemplateScreen.id &&
                  this.state.clientEditScreen.id
                ) {
                  this.grexSuperAdminTemplateModelApi
                    .apiGrexsuperadminClientTemplateModelRetrieve({
                      clientId: this.state.clientEditScreen.id,
                      templateModelId: this.state.editModelTemplateScreen.id,
                    })
                    .then((response) => {
                      this.state.editModelTemplateScreen.history =
                        response.history;
                      callback(this.state);
                    })
                    .catch((error) => {
                      console.log(error);
                      callback(this.state);
                    });
                }
              } else {
                this.state.editModelTemplateScreen.formError =
                  "Upload niet geslaagd.";
                this.state.loading = false;
                callback(this.state);
                setTimeout(() => {
                  this.state.editModelTemplateScreen.formError = "";
                  callback(this.state);
                }, 2000);
              }
            }
          };
          xhr.open("POST", url, true);
          xhr.setRequestHeader(
            "Authorization",
            `Bearer ${this.state.currentUser.accessToken || ""}`
          );
          xhr.send(formData);
        } else {
          this.state.editModelTemplateScreen.formError =
            "Selecteer een bestand.";
          callback(this.state);
          setTimeout(() => {
            this.state.editModelTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        }
      } else {
        this.state.editModelTemplateScreen.formError =
          "Vul een datasheet naam in.";
        callback(this.state);
        setTimeout(() => {
          this.state.editModelTemplateScreen.formError = "";
          callback(this.state);
        }, 2000);
      }
    }
  }

  activateModelTemplateScreen(
    this: AppStateHandler,
    historyId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.editModelTemplateScreen.id &&
      this.state.clientEditScreen.id
    ) {
      if (this.state.editModelTemplateScreen.displayName !== "") {
        this.grexSuperAdminTemplateModelApi
          .apiGrexsuperadminClientTemplateModelUpdate({
            clientId: this.state.clientEditScreen.id,
            templateModelId: this.state.editModelTemplateScreen.id,
            clientUpdateTemplateModelRequest: {
              name: this.state.editModelTemplateScreen.displayName,
              description: this.state.editModelTemplateScreen.description,
              active: historyId,
            },
          })
          .then((response) => {
            this.state.editModelTemplateScreen.successMessage = "Opgeslagen";
            callback(this.state);
            setTimeout(() => {
              this.state.editModelTemplateScreen.successMessage = "";
              callback(this.state);
            }, 2000);

            // Reload list
            if (
              this.state.editModelTemplateScreen.id &&
              this.state.clientEditScreen.id
            ) {
              this.grexSuperAdminTemplateModelApi
                .apiGrexsuperadminClientTemplateModelRetrieve({
                  clientId: this.state.clientEditScreen.id,
                  templateModelId: this.state.editModelTemplateScreen.id,
                })
                .then((response) => {
                  this.state.editModelTemplateScreen.history = response.history;
                  callback(this.state);
                })
                .catch((error) => {
                  console.log(error);
                  this.state.editModelTemplateScreen.formError =
                    "Error tijdens herladen scherm.";
                  callback(this.state);
                  setTimeout(() => {
                    this.state.editModelTemplateScreen.formError = "";
                    callback(this.state);
                  }, 2000);
                });
            }
          })
          .catch((error) => {
            this.state.editModelTemplateScreen.formError =
              "Error tijdens opslaan.";
            callback(this.state);
            console.log(error);
            setTimeout(() => {
              this.state.editModelTemplateScreen.formError = "";
              callback(this.state);
            }, 2000);
          });
      } else {
        this.state.editReportTemplateScreen.formError =
          "Vul een zichtbare naam in.";
        callback(this.state);
        setTimeout(() => {
          this.state.editModelTemplateScreen.formError = "";
          callback(this.state);
        }, 2000);
      }
    }
  }

  deleteEditModelTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.editModelTemplateScreen.id &&
      this.state.clientEditScreen.id
    ) {
      this.state.loading = true;
      callback(this.state);
      this.grexSuperAdminTemplateModelApi
        .apiGrexsuperadminClientTemplateModelDestroy({
          clientId: this.state.clientEditScreen.id,
          templateModelId: this.state.editModelTemplateScreen.id,
        })
        .then((response) => {
          this.state.loading = false;
          callback(this.state);
          if (this.state.clientEditScreen.id) {
            this.edit_client_init_screen(
              this.state.clientEditScreen.id,
              callback
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.state.editModelTemplateScreen.formError =
            "Error tijdens deleten.";
          this.state.loading = false;
          callback(this.state);
          setTimeout(() => {
            this.state.editModelTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        });
    }
  }
}
