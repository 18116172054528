import { DocumentationTypeEnum, RoleEnum, StateEnum } from "@shared/client/lib";
import { AppStateType, ScreenState } from "./Types";

export const initialAppState: AppStateType = {
  loggedIn: false,
  currentUser: {
    fullName: "",
    userRole: RoleEnum.Non,
    accessToken: null,
    userEmail: "",
    mfaVerified: false,
    mfaForced: false,
    mfaInput: "",
  },
  LoginScreen: {
    login_error: "",
  },
  // loginState: LoginState.LoginEmailPassword,
  mfaRequired: false,
  login2fa: {
    useremail: "",
    infoMessage: "",
    errorMessage: "",
    mfaInput: "",
  },
  // Setup Mfa
  setupMfa: {
    mfaCode: "",
    otpauth_url: "",
    errorMessage: "",
  },
  // // // reset password
  // ResetPassword: {
  //   email: "",
  //   resetPasswordCode: "",
  //   mfaenabled: false,
  //   mfatoken: "",
  //   resetpasswordMessage: "",
  //   oldPassword: "",
  //   newPassword: "",
  //   newPasswordRepeat: "",
  //   newPasswordError: "",
  //   newPasswordinfo: "",
  // },

  loading: false,

  screenState: ScreenState.Clients,

  clientsScreen: {
    clients: [],
  },

  clientEditScreen: {
    loadingClientName: false,
    id: null,
    name: "",
    subdomain: "",

    loadingMapViewerSettings: false,
    mapViewerHost: "",
    mapViewerClientId: "",
    mapViewerClientSecret: "",
    mapViewerRedirectUri: "",
    mapViewerApiKeyId: "",
    mapViewerApiKey: "",

    loadingTemplateModelList: false,
    templateModelList: [],

    loadingTemplateReportList: false,
    templateReportList: [],
  },
  samlscreen: {
    client_id: 0,
    loadingSamlData: false,
    samlEnabled: false,
    samlEnabledSavedValue: false,
    samlEntityId: "",
    samlSSOService: "",
    samlSLService: "",
    samlCertificate: "",
    saml_grex_sp_EntityId: "",
    saml_grex_sp_ACS: "",
    saml_grex_sp_sls: "",
    new_samlEntityId: "",
    new_samlSSOService: "",
    new_samlSLService: "",
    new_samlCertificate: "",
    new_saml_form_error: "",
    new_saml_form_info: "",
  },
  logScreen: {
    logs: [],
  },

  clientLogScreen: {
    logs: [],
  },

  userScreen: {
    userId: 0,
    firstName: "",
    lastName: "",
    email: "",
    mfaVerified: false,
    mfaForced: false,
    infoMessage: "",
    errorMessage: "",
  },

  userNewPasswordScreen: {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    newPasswordError: "",
    newPasswordinfo: "",
  },

  newClientScreen: {
    clientName: "klant",
    availableClientName: false,
    message: "",
  },

  PasswordPolicy: {
    newPasswordRequirements: [
      "Minimaal 12 karakters",
      "Minimaal één hoofdletter",
      "Minimaal één kleine letter",
      "Minimaal één cijfer",
      "Minimaal één speciaal teken",
    ],
    notAppliedRequirements: [],
  },

  addModelTemplateScreen: {
    formError: "",
    displayName: "",
    description: "",
    datasheetName: "data",
    file: null,
  },

  editModelTemplateScreen: {
    id: null,
    formError: "",
    successMessage: "",
    displayName: "",
    description: "",
    datasheetName: "data",
    file: null,
    history: [],
  },

  addReportTemplateScreen: {
    formError: "",
    displayName: "",
    description: "",
    status: StateEnum.Inactive,
    nModelsMin: "1",
    nModelsMax: "1000",
    file: null,
  },

  documentationList: [],
  selectedDocumentation: null,
  editDocumentationScreen: {
    name: "",
    description: "",
    pdfPath: "",
    videoUrl: "",
    documentationType: "",
    access: [],
    file: null,
    message: "",
  },
  documentationAccess: [
    {
      client_id: 0,
      client_name: "",
      access: false,
    },
  ],

  newDocumentationScreen: {
    name: "",
    description: "",
    documentationType: DocumentationTypeEnum.Manual,
    pdfPath: "",
    file: null,
    videoUrl: "",
    access: [],
    message: "",
  },

  editReportTemplateScreen: {
    id: null,
    formError: "",
    successMessage: "",
    displayName: "",
    description: "",
    status: StateEnum.Inactive,
    nModelsMin: "1",
    nModelsMax: "1000",
    file: null,
    history: [],
  },

  editSubregionScreen: {
    formError: "",
    successMessage: "",
    subregionName: "",
  },
};

export const initialAppStateString: string = JSON.stringify(initialAppState);
