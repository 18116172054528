import React from "react";
import "./LoginMfaScreen.css";
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";

interface LoginMfaScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class LoginMfaScreen extends React.Component<LoginMfaScreenProps> {
  render() {
    return (
      <div id="LoginMfaScreen-Container">
        <div id="LoginMfaScreen-Section">
          <GrexManagerLogo />

          <div id="LoginMfaScreen-LoginMfaHolder">
            <SectionTitle>Multi-Factor Authenticatie (MFA)</SectionTitle>
            {(() => {
              if (this.props.state.login2fa.errorMessage !== "") {
                return (
                  <div className="errorMessage">
                    <p>{this.props.state.login2fa.errorMessage}</p>
                  </div>
                );
              }
            })()}
            <div className="label">MFA Code</div>
            <TextInput
              type={TextInputType.Text}
              placeholder="XXXXXX"
              value={this.props.state.login2fa.mfaInput}
              onChange={(newValue: string) => {
                this.props.stateHandler.check_mfa_code(
                  newValue,
                  this.props.updateStateCallback
                );
              }}
            ></TextInput>
          </div>
        </div>
      </div>
    );
  }
}
