import "./ClientLogScreen.css";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { formatDate } from "@shared/utils/helperFunctions";

interface ClientLogScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ClientLogScreen extends React.Component<ClientLogScreenProps> {
  render() {
    return (
      <div id="ClientLogScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klanten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klant bewerken",
              callback: () => {
                if (this.props.state.clientEditScreen.id) {
                  this.props.stateHandler.edit_client_init_screen(
                    this.props.state.clientEditScreen.id,
                    this.props.updateStateCallback
                  );
                }
              },
            },
            {
              title: "Logs",
            },
          ]}
        ></BreadCrumbs>

        <div id="ClientLogScreen-ScrollView">
          <div id="ClientLogScreen-ScrollHolder">
            <table id="ClientLogScreen-Table">
              <thead>
                <tr>
                  <th>Datum + Tijd</th>
                  <th>Gebruiker</th>
                  <th>Actie</th>
                  <th>IP-adres</th>
                </tr>
              </thead>
              <tbody>
                {this.props.state.clientLogScreen.logs.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{formatDate(item.timestamp)}</td>
                      <td>{item.userNameFull}</td>
                      <td>{item.description}</td>
                      <td>{item.ipaddress}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
