import {
  ApiGrexsuperadminDocumentationCreateRequest,
  DocumentationTypeEnum,
} from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class NewDocumentationStateHandler {
  loadNewDocumentationinfo(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.NewDocumentation;

    this.state.documentationAccess.splice(
      0,
      this.state.documentationAccess.length
    );
    this.grexSuperAdminClientApi
      .apiGrexsuperadminClientList()
      .then((clients) => {
        clients.map((client) => {
          this.state.documentationAccess.push({
            client_id: client.id,
            client_name: client.name,
            access: false,
          });
          return this.state.documentationAccess;
        });
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
    this.state.newDocumentationScreen = {
      name: "",
      description: "",
      documentationType: DocumentationTypeEnum.Manual,
      pdfPath: "",
      file: null,
      videoUrl: "",
      access: [],
      message: "",
    };
  }

  newDocumentationForm(
    this: AppStateHandler,
    newDocumentation: {
      name?: string;
      description?: string;
      documentationType?: DocumentationTypeEnum;
      files?: FileList | null;
      pdfPath?: string;
      videoUrl?: string;
      access?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    this.state.newDocumentationScreen.message = "";
    if (newDocumentation.name !== undefined) {
      this.state.newDocumentationScreen.name = newDocumentation.name;
    }
    if (newDocumentation.description !== undefined) {
      this.state.newDocumentationScreen.description =
        newDocumentation.description;
    }
    if (newDocumentation.documentationType !== undefined) {
      this.state.newDocumentationScreen.documentationType =
        newDocumentation.documentationType;
    }
    if (newDocumentation.pdfPath !== undefined) {
      this.state.newDocumentationScreen.pdfPath = newDocumentation.pdfPath;
    }
    if (newDocumentation.videoUrl !== undefined) {
      this.state.newDocumentationScreen.videoUrl = newDocumentation.videoUrl;
    }
    if (newDocumentation.files !== undefined) {
      if (newDocumentation.files !== null) {
        this.state.newDocumentationScreen.file = newDocumentation.files[0];
      }
    }
    if (newDocumentation.access) {
      this.state.documentationAccess.map((item) => {
        if (item.client_id === newDocumentation.access) {
          item.access = item.access ? false : true;
        }
        return item;
      });
    }

    callback(this.state);
  }

  validateNewDocumentationForm(this: AppStateHandler): {
    success: boolean;
    message: string;
  } {
    if (
      this.state.newDocumentationScreen.name === "" ||
      this.state.newDocumentationScreen.description === "" ||
      this.state.newDocumentationScreen.access.length === 0
    ) {
      return { success: false, message: "Niet alle velden zijn ingevuld" };
    }
    if (
      this.state.newDocumentationScreen.documentationType ===
      DocumentationTypeEnum.InstructionalVideo
    ) {
      return {
        success: this.state.newDocumentationScreen.videoUrl !== "",
        message: "Youtube link is niet ingevuld of niet geldig",
      };
    } else {
      return {
        success: this.state.newDocumentationScreen.file !== null,
        message: "PDF bestand is niet ingevuld",
      };
    }
  }

  createDocumentation(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // map access to array of client_id
    this.state.documentationAccess.map((item) => {
      if (item.access) {
        this.state.newDocumentationScreen.access.push(item.client_id);
      }
      return item;
    });
    const validate = this.valisateNewDocumentationForm();
    if (!validate.success) {
      this.state.newDocumentationScreen.message = validate.message;
      callback(this.state);
      return;
    } else {
      if (
        this.state.newDocumentationScreen.documentationType ===
        DocumentationTypeEnum.InstructionalVideo
      ) {
        const request: ApiGrexsuperadminDocumentationCreateRequest = {
          documentationSuperAdmin: {
            id: 0,
            name: this.state.newDocumentationScreen.name,
            description: this.state.newDocumentationScreen.description,
            documentationType:
              this.state.newDocumentationScreen.documentationType,
            pdfPath: this.state.newDocumentationScreen.pdfPath,
            videoUrl: this.state.newDocumentationScreen.videoUrl,
            access: this.state.newDocumentationScreen.access,
          },
        };
        this.grexSuperAdminDocumentationApi
          .apiGrexsuperadminDocumentationCreate(request)
          .then((response) => {
            if (response) {
              this.changeScreen(ScreenState.DocumentationList, callback);
              this.state.newDocumentationScreen.message =
                this.state.newDocumentationScreen.name + "is aangemaakt!";
              setTimeout(() => {
                this.state.newDocumentationScreen.message = "";
                callback(this.state);
              }, 2000);
              callback(this.state);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        var formData = new FormData();
        formData.append("name", `${this.state.newDocumentationScreen.name}`);
        formData.append(
          "description",
          `${this.state.newDocumentationScreen.description}`
        );
        formData.append(
          "documentationType",
          `${this.state.newDocumentationScreen.documentationType}`
        );
        if (this.state.newDocumentationScreen.file) {
          formData.append("file", this.state.newDocumentationScreen.file);
        }
        formData.append(
          "pdfPath",
          `${this.state.newDocumentationScreen.pdfPath}`
        );
        formData.append(
          "videoUrl",
          `${this.state.newDocumentationScreen.videoUrl}`
        );
        formData.append(
          "access",
          `${this.state.newDocumentationScreen.access}`
        );

        const url = `${this.basePath()}/api/grexsuperadmin/documentation/`;
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4) {
            if (xhr.status === 201) {
              this.changeScreen(ScreenState.DocumentationList, callback);
              this.state.newDocumentationScreen.message =
                this.state.newDocumentationScreen.name + "is aangemaakt!";
              setTimeout(() => {
                this.state.newDocumentationScreen.message = "";
                callback(this.state);
              }, 2000);
              callback(this.state);
            } else {
              this.state.newDocumentationScreen.message =
                "Upload niet geslaagd.";
              callback(this.state);
            }
          }
        };
        xhr.open("POST", url, true);
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.state.currentUser.accessToken || ""}`
        );
        xhr.send(formData);
      }
    }
  }
}
