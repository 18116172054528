import "./ClientsScreen.css";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import ButtonList from "@shared/components/ButtonList";
import Alert from "@shared/components/Alert";
interface ClientsScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ClientsScreen extends React.Component<ClientsScreenProps> {
  render() {
    return (
      <div id="ClientsScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klanten",
            },
          ]}
        ></BreadCrumbs>

        {(() => {
          if (this.props.state.newClientScreen.message !== "") {
            return (
              <Alert
                Error={false}
                Message={this.props.state.newClientScreen.message}
              ></Alert>
            );
          }
        })()}
        <div id="ClientsScreen-ScrollView">
          <div id="ClientsScreen-ScrollHolder">
            <table id="ClientsScreen-Table">
              <thead>
                <tr>
                  <th>Klantnaam</th>
                  <th>Domein</th>
                </tr>
              </thead>
              <tbody>
                {this.props.state.clientsScreen.clients.map((item) => {
                  return (
                    <tr
                      key={item.id}
                      onClick={() => {
                        this.props.stateHandler.edit_client_button_clicked(
                          item.id,
                          this.props.updateStateCallback
                        );
                      }}
                    >
                      <td>{item.name}</td>
                      <td>{item.subdomain}.grexmanager.nl</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <ButtonList
          data={[
            {
              title: "Klant aanmaken",
              callback: () => {
                this.props.stateHandler.add_client_button_clicked(
                  this.props.updateStateCallback
                );
              },
              disabled: false,
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
