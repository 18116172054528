import "./LogScreen.css";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { formatDate } from "@shared/utils/helperFunctions";

interface LogScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class LogScreen extends React.Component<LogScreenProps> {
  render() {
    return (
      <div id="LogScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Logs",
            },
          ]}
        ></BreadCrumbs>

        <div id="LogScreen-ScrollView">
          <div id="LogScreen-ScrollHolder">
            <table id="LogScreen-Table">
              <thead>
                <tr>
                  <th>Datum + Tijd</th>
                  <th>Gebruiker</th>
                  <th>Actie</th>
                  <th>IP-adres</th>
                  <th>Klant</th>
                </tr>
              </thead>
              <tbody>
                {this.props.state.logScreen.logs.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{formatDate(item.timestamp)}</td>
                      <td>{item.userNameFull}</td>
                      <td>{item.description}</td>
                      <td>{item.ipaddress}</td>
                      <td>
                        {item.clientName === "Central schema"
                          ? "Superadmin"
                          : item.clientName}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
