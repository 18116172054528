import { DocumentationTypeEnum } from "@shared/client/lib/models/DocumentationTypeEnum";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import GrexInfo from "@shared/GrexInfo";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import "./DocumentationListScreen.css";

interface DocumentationListScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class DocumentationListScreen extends React.Component<DocumentationListScreenProps> {
  render() {
    return (
      <div id="DocumentationListScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexSuperAdmin",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Clients,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Documentatie",
            },
          ]}
        ></BreadCrumbs>
        {(() => {
          if (this.props.state.newDocumentationScreen.message !== "") {
            return (
              <Alert
                Error={false}
                Message={this.props.state.newDocumentationScreen.message}
              ></Alert>
            );
          }
        })()}

        <div id="DocumentationListScreen-ScrollView">
          <div id="DocumentationListScreen-ScrollHolder">
            <table id="DocumentationListScreen-Table">
              <thead>
                <tr>
                  <th>Zichtbare naam</th>
                  <th>Categorie</th>
                  <th>Type</th>
                  <th>Aantal klanten</th>
                </tr>
              </thead>
              <tbody>
                {this.props.state.documentationList.map((item) => {
                  return (
                    <tr
                      key={item.id}
                      onClick={() =>
                        this.props.stateHandler.selectDocumentation(
                          item,
                          this.props.updateStateCallback
                        )
                      }
                    >
                      <td>{item.name}</td>
                      <td>
                        {item.documentationType ===
                        DocumentationTypeEnum.InstructionalVideo
                          ? "Instructievideo's"
                          : "Handleidingen"}
                      </td>
                      <td>
                        {item.documentationType ===
                        DocumentationTypeEnum.InstructionalVideo
                          ? "YouTube"
                          : "PDF"}
                      </td>
                      <td>{item.access.length}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <ButtonList
          data={[
            {
              title: "Documentatie toevoegen",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.NewDocumentation,
                  this.props.updateStateCallback
                );
              },
              disabled: false,
            },
          ]}
        ></ButtonList>
        <div id="DocumentationListScreen-GrexVersion">
          GrexSuperAdmin {GrexInfo.version}
        </div>
      </div>
    );
  }
}
