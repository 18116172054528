import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class AddModelTemplateStateHandler {
  initAddModelTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.AddModelTemplate;
    callback(this.state);
  }

  updateAddModelTemplateScreen(
    this: AppStateHandler,
    newFormValues: {
      displayName?: string;
      description?: string;
      datasheetName?: string;
      files?: FileList | null;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.displayName !== undefined) {
      this.state.addModelTemplateScreen.displayName = newFormValues.displayName;
    }
    if (newFormValues.description !== undefined) {
      this.state.addModelTemplateScreen.description = newFormValues.description;
    }
    if (newFormValues.datasheetName !== undefined) {
      this.state.addModelTemplateScreen.datasheetName =
        newFormValues.datasheetName;
    }
    if (newFormValues.files !== undefined) {
      if (newFormValues.files !== null) {
        this.state.addModelTemplateScreen.file = newFormValues.files[0];
      }
    }
    callback(this.state);
  }

  saveAddModelTemplateScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.clientEditScreen.id) {
      if (this.state.addModelTemplateScreen.displayName !== "") {
        if (this.state.addModelTemplateScreen.datasheetName !== "") {
          if (this.state.addModelTemplateScreen.file) {
            this.state.loading = false;
            callback(this.state);
            var formData = new FormData();
            formData.append(
              "name",
              `${this.state.addModelTemplateScreen.displayName}`
            );
            formData.append(
              "description",
              `${this.state.addModelTemplateScreen.description}`
            );
            formData.append(
              "datasheet_name",
              `${this.state.addModelTemplateScreen.datasheetName}`
            );
            formData.append("file", this.state.addModelTemplateScreen.file);
            formData.append("active", "True");

            const url = `${this.basePath()}/api/grexsuperadmin/client/${
              this.state.clientEditScreen.id
            }/template-model/`;
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = (e) => {
              if (xhr.readyState === 4) {
                if (xhr.status === 201) {
                  this.state.loading = false;
                  callback(this.state);
                  if (this.state.clientEditScreen.id) {
                    this.edit_client_init_screen(
                      this.state.clientEditScreen.id,
                      callback
                    );
                  }
                } else {
                  this.state.addModelTemplateScreen.formError =
                    "Upload niet geslaagd.";
                  this.state.loading = false;
                  callback(this.state);
                  setTimeout(() => {
                    this.state.addModelTemplateScreen.formError = "";
                    callback(this.state);
                  }, 2000);
                }
              }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader(
              "Authorization",
              `Bearer ${this.state.currentUser.accessToken || ""}`
            );
            xhr.send(formData);
          } else {
            this.state.addModelTemplateScreen.formError =
              "Selecteer een bestand.";
            callback(this.state);
            setTimeout(() => {
              this.state.addModelTemplateScreen.formError = "";
              callback(this.state);
            }, 2000);
          }
        } else {
          this.state.addReportTemplateScreen.formError =
            "Vul een datasheet naam in.";
          callback(this.state);
          setTimeout(() => {
            this.state.addModelTemplateScreen.formError = "";
            callback(this.state);
          }, 2000);
        }
      } else {
        this.state.addModelTemplateScreen.formError = "Vul een naam in.";
        callback(this.state);
        setTimeout(() => {
          this.state.addModelTemplateScreen.formError = "";
          callback(this.state);
        }, 2000);
      }
    }
  }
}
