import {
  ApiGrexsuperadminDocumentationUpdateRequest,
  DocumentationTypeEnum,
} from "@shared/client/lib";
import { ApiGrexmanagerDocumentationRetrieveRequest } from "@shared/client/lib/apis/DocumentationApi";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class DocumentationStateHandler {
  loadDocumentationinfo(
    this: AppStateHandler,

    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.EditDocumentation;
    const documentation = this.state.selectedDocumentation;
    if (documentation !== null) {
      this.state.editDocumentationScreen = {
        name: documentation.name,
        description: documentation.description,
        pdfPath: documentation.pdfPath || "",
        videoUrl: documentation.videoUrl || "",
        message: "",
        file: null,
        documentationType: documentation.documentationType,
        access: documentation.access,
      };
    }

    this.state.documentationAccess.splice(
      0,
      this.state.documentationAccess.length
    );
    this.grexSuperAdminClientApi
      .apiGrexsuperadminClientList()
      .then((clients) => {
        clients.map((client) => {
          const access = this.state.editDocumentationScreen.access.includes(
            client.id
          );

          this.state.documentationAccess.push({
            client_id: client.id,
            client_name: client.name,
            access,
          });
          return client;
        });
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  OpenPdfFile(
    this: AppStateHandler,
    documentationId: number,
    callback: (newState: AppStateType) => void
  ) {
    const request: ApiGrexmanagerDocumentationRetrieveRequest = {
      id: documentationId,
    };
    this.grexManagerdocumentationApi
      .apiGrexmanagerDocumentationRetrieve(request)
      .then((documentation: any) => {
        const pdfURL = URL.createObjectURL(documentation);
        window.open(pdfURL, "_blank");
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteDocumentation(
    this: AppStateHandler,
    documentationId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.grexSuperAdminDocumentationApi
      .apiGrexsuperadminDocumentationDestroy({
        id: documentationId,
      })
      .then(() => {
        this.state.selectedDocumentation = null;
        this.state.documentationList = [];
        this.changeScreen(ScreenState.DocumentationList, callback);
        this.state.editDocumentationScreen.message =
          this.state.newDocumentationScreen.name + "is verwijderd.";
        setTimeout(() => {
          this.state.newDocumentationScreen.message = "";
          callback(this.state);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
    callback(this.state);
  }

  editDocumentationForm(
    this: AppStateHandler,
    EditDocumentation: {
      name?: string;
      description?: string;
      documentationType?: DocumentationTypeEnum;
      files?: FileList | null;
      pdfPath?: string;
      videoUrl?: string;
      access?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    this.state.editDocumentationScreen.message = "";
    if (EditDocumentation.name !== undefined) {
      this.state.editDocumentationScreen.name = EditDocumentation.name;
    }
    if (EditDocumentation.description !== undefined) {
      this.state.editDocumentationScreen.description =
        EditDocumentation.description;
    }
    if (EditDocumentation.documentationType !== undefined) {
      this.state.editDocumentationScreen.documentationType =
        EditDocumentation.documentationType;
    }
    if (EditDocumentation.pdfPath !== undefined) {
      this.state.editDocumentationScreen.pdfPath = EditDocumentation.pdfPath;
    }
    if (EditDocumentation.videoUrl !== undefined) {
      this.state.editDocumentationScreen.videoUrl = EditDocumentation.videoUrl;
    }
    if (EditDocumentation.files !== undefined) {
      if (EditDocumentation.files !== null) {
        this.state.editDocumentationScreen.file = EditDocumentation.files[0];
      }
    }
    if (EditDocumentation.access) {
      this.state.documentationAccess.map((item) => {
        if (item.client_id === EditDocumentation.access) {
          item.access = item.access ? false : true;
        }
        return item;
      });
    }

    callback(this.state);
  }

  validateEditDocumentationForm(this: AppStateHandler): {
    success: boolean;
    message: string;
  } {
    if (
      this.state.editDocumentationScreen.name === "" ||
      this.state.editDocumentationScreen.description === "" ||
      this.state.editDocumentationScreen.access.length === 0
    ) {
      return { success: false, message: "Niet alle velden zijn ingevuld" };
    }
    if (
      this.state.editDocumentationScreen.documentationType ===
      DocumentationTypeEnum.InstructionalVideo
    ) {
      return {
        success: this.state.editDocumentationScreen.videoUrl !== "",
        message: "Youtube link is niet ingevuld of niet geldig",
      };
    } else {
      return {
        success: this.state.editDocumentationScreen.pdfPath !== "",
        message: "Geen bestand geselecteerd",
      };
    }
  }

  saveDocumentation(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // map access to array of client_id
    this.state.editDocumentationScreen.access = [];
    this.state.documentationAccess.map((item) => {
      if (item.access) {
        this.state.editDocumentationScreen.access.push(item.client_id);
      }
      return item;
    });

    const validate = this.validateEditDocumentationForm();
    if (!validate.success) {
      this.state.editDocumentationScreen.message = validate.message;
      callback(this.state);
      return;
    } else {
      if (
        this.state.editDocumentationScreen.documentationType ===
        DocumentationTypeEnum.InstructionalVideo
      ) {
        const request: ApiGrexsuperadminDocumentationUpdateRequest = {
          id: this.state.selectedDocumentation?.id || 0,
          documentationSuperAdmin: {
            id: 0,
            name: this.state.editDocumentationScreen.name,
            description: this.state.editDocumentationScreen.description,
            documentationType:
              this.state.editDocumentationScreen.documentationType,
            pdfPath: this.state.editDocumentationScreen.pdfPath,
            videoUrl: this.state.editDocumentationScreen.videoUrl,
            access: this.state.editDocumentationScreen.access,
          },
        };
        this.grexSuperAdminDocumentationApi
          .apiGrexsuperadminDocumentationUpdate(request)
          .then((response) => {
            if (response) {
              this.changeScreen(ScreenState.DocumentationList, callback);
              this.state.newDocumentationScreen.message =
                this.state.editDocumentationScreen.name + "is aangepast!";
              setTimeout(() => {
                this.state.editDocumentationScreen.message = "";
                callback(this.state);
              }, 2000);
              callback(this.state);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        var formData = new FormData();
        formData.append("name", `${this.state.editDocumentationScreen.name}`);
        formData.append(
          "description",
          `${this.state.editDocumentationScreen.description}`
        );
        formData.append(
          "documentationType",
          `${this.state.editDocumentationScreen.documentationType}`
        );
        if (this.state.editDocumentationScreen.file) {
          formData.append("file", this.state.editDocumentationScreen.file);
        }
        formData.append(
          "pdfPath",
          `${this.state.editDocumentationScreen.pdfPath}`
        );
        formData.append(
          "videoUrl",
          `${this.state.editDocumentationScreen.videoUrl}`
        );
        formData.append(
          "access",
          `${this.state.editDocumentationScreen.access}`
        );

        const url = `${this.basePath()}/api/grexsuperadmin/documentation/?id=${
          this.state.selectedDocumentation?.id
        }`;
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              this.changeScreen(ScreenState.DocumentationList, callback);
              this.state.newDocumentationScreen.message =
                this.state.editDocumentationScreen.name + "is aangemaakt!";
              setTimeout(() => {
                this.state.editDocumentationScreen.message = "";
                callback(this.state);
              }, 2000);
              callback(this.state);
            } else {
              this.state.editDocumentationScreen.message =
                "Upload niet geslaagd.";
              callback(this.state);
            }
          }
        };
        xhr.open("PUT", url, true);
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.state.currentUser.accessToken || ""}`
        );
        xhr.send(formData);
      }
    }
  }
}
