import { DocumentationTypeEnum } from "@shared/client/lib";
import { DropDownListItem } from "@shared/components/DropDown";

export const checkPasswordPolicy = (
  requirements: string[],
  password: string,
  notAppliedRequirements: string[]
): void => {
  for (const requirement of requirements) {
    if (!doesPasswordMeetRequirement(requirement, password)) {
      if (!notAppliedRequirements.includes(requirement)) {
        notAppliedRequirements.push(requirement);
      }
    } else {
      if (notAppliedRequirements.includes(requirement)) {
        notAppliedRequirements.splice(
          notAppliedRequirements.indexOf(requirement),
          1
        );
      }
    }
  }
};

export const doesPasswordMeetRequirement = (
  requirement: string,
  password: string
): boolean => {
  if (requirement === "Minimaal 12 karakters") {
    if (password.length < 12) {
      return false;
    }
  }
  if (requirement === "Minimaal één hoofdletter") {
    if (password.toLowerCase() === password) {
      return false;
    }
  }
  if (requirement === "Minimaal één kleine letter") {
    if (password.toUpperCase() === password) {
      return false;
    }
  }
  if (requirement === "Minimaal één cijfer") {
    if (!/\d/.test(password)) {
      return false;
    }
  }
  if (requirement === "Minimaal één speciaal teken") {
    if (!/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password)) {
      return false;
    }
  }
  return true;
};

// Functions to convert DocumentationTypeEnum enum to DropDownListItems
export const DocumentationToDropDown = (): DropDownListItem[] => {
  return (
    Object.keys(DocumentationTypeEnum) as Array<
      keyof typeof DocumentationTypeEnum
    >
  ).map((key, index) => {
    let displayName = "???";
    switch (DocumentationTypeEnum[key]) {
      case DocumentationTypeEnum.InstructionalVideo:
        displayName = "Instructievideo's";
        break;
      case DocumentationTypeEnum.Manual:
        displayName = "Handleidingen";
        break;
      case DocumentationTypeEnum.ReleaseNote:
        displayName = "Release notes";
        break;
      default:
        break;
    }
    return {
      key: index,
      name: displayName,
      disabled: false,
    };
  });
};
export const DocumentationTypeToIndex = (
  type: DocumentationTypeEnum
): number => {
  return Object.values(DocumentationTypeEnum).indexOf(type);
};
export const IndexToDocumentationType = (
  DocumentationTypeEnumIndex: number
): DocumentationTypeEnum => {
  const key = Object.keys(DocumentationTypeEnum)[
    DocumentationTypeEnumIndex
  ] as keyof typeof DocumentationTypeEnum;
  return DocumentationTypeEnum[key];
};
